<div class="container">
  <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">{{ msjResp.text }}</p>
    </div>
  </div>

  <div nz-row class="mt-3" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button class="btn btn-secondary" (click)="closeModal()">Aceptar</button>
    </div>
  </div>
</div>
