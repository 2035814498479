<div *ngIf="spin">
  <div
    class="mt-4"
    nz-row
    nzAlign="middle"
    nzJustify="center"
    class="spinner-modal"
  >
    <!-- *ngIf="apiBusyModal" -->
    <nz-spin nzSize="large" nzSimple></nz-spin>
  </div>

  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="mt-4 container-text">
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="ajuste-text" *ngIf="aprobado">
  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-bold text-title text-medium text-primary">
        {{ aprobado.title }}
      </p>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="container-subtitle">
        <p class="text-black text-bold">
          {{ aprobado.subtitle1 }}
          <span class="text-black text-bold text-medium">{{
            aprobado.subtitle2
          }}</span>
        </p>
      </div>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="container-description">
        <p class="text-center">{{ aprobado.text }}</p>
      </div>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <!-- (click)="toModalPremium()" -->
      <button class="btn btn-secondary" (click)="toModalPremium()">
        {{ aprobado.buttonSecondary }}
      </button>
    </div>
  </div>

  <div nz-row class="mt-1" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button class="btn btn-outline-primary">
        {{ aprobado.buttonOutline }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="solicitRecibida">
  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-bold text-title text-medium text-primary">
        {{ solicitRecibida.title }}
      </p>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="container-description">
        <p class="text-center">{{ solicitRecibida.text1 }}</p>
      </div>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="container-description">
        <p class="text-center">{{ solicitRecibida.text2 }}</p>
      </div>
    </div>
  </div>

  <div nz-row class="mt-2" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeModal()" class="btn btn-secondary">
        {{ solicitRecibida.button }}
      </button>
    </div>
  </div>
</div>
