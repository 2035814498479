import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { setFloatToString } from 'src/functions/common-functions';
import { fvalidatorFactura } from 'src/functions/custom-validators';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CajaService } from 'projects/agencia/src/app/caja/services/caja.service';

@Component({
  selector: 'table-editable',
  templateUrl: './table-editable.component.html',
  styleUrls: ['./table-editable.component.scss'],
})
export class TableEditableComponent implements OnInit {
  @Input() rows;
  @Input() tableHeaders;
  @Input() isCheckbox;
  @Input() enableField;
  @Input() rowsAux;
  @Input() widthConfig;

  @Output() sendRowData = new EventEmitter<any>();
  @Output() eventAction = new EventEmitter<any>();
  @Output() eventUpdateValue = new EventEmitter<any>();
  @Output() eventDataSelected = new EventEmitter<any>();
  @Output() eventPositionError = new EventEmitter<any>();

  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<any>;
  listOfData = [];
  private destroy$ = new Subject();

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: any[] = [];
  setOfCheckedId = new Set();
  arrayData: any[] = [];
  formTableEditable: FormGroup;
  editId: string | null = null;

  constructor(public fb: FormBuilder, public apiCaja: CajaService) {
    this.formTableEditable = this.fb.group({
      valores: this.fb.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.rows?.currentValue);

    this.listOfData = changes.rows?.currentValue
      ? changes.rows?.currentValue
      : this.listOfData;

    this.eventDataSelected.emit(this.listOfData);
  }

  ngAfterViewInit(): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrolledIndexChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number) => {
        // console.log('scroll index to', data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.apiCaja.detectedErrorScrolling$.subscribe((data: any) => {
      this.scrollToIndex(data.index);
    });

    this.listOfData = this.rows;

    this.rows.forEach((element) => {
      let valor = this.fb.group({
        pagoMinimo: new FormControl(
          setFloatToString(element.pagoMinimo),
          Validators.compose([fvalidatorFactura(), Validators.required])
        ),
        valorFactura: new FormControl(
          setFloatToString(element.valorFactura),
          Validators.compose([fvalidatorFactura(), Validators.required])
        ),
      });
      this.valores.push(valor);
    });

    console.log('lista data:', this.rows);

    this.eventDataSelected.emit(this.rows);
  }

  get valores(): FormArray {
    return this.formTableEditable.get('valores') as FormArray;
  }

  onCurrentPageDataChange(e): void {
    this.listOfCurrentPageData = e;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );

    this.checked = listOfEnabledData.every((item) => {
      this.setOfCheckedId.has(item.id);
    });
    this.indeterminate =
      listOfEnabledData.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked;
  }

  unsorted() {}

  onItemChecked(index, e, data) {
    // this.updateCheckedSet(index, e, data);
    this.refreshCheckedStatus();
  }

  isObject(obj) {
    return typeof obj === 'object';
  }

  isEditable(obj) {
    if (obj === 'pagoMinimo' || obj === 'valorFactura') {
      return true;
    } else {
      return false;
    }
  }

  selectedRow(data) {
    this.sendRowData.emit(data);
  }

  actionEmit(data) {
    console.log(data);

    this.eventAction.emit(data);
  }

  trackByIndex(_: number, data: any): number {
    return data.id;
  }

  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }

  getValidField(index, value, valueConditional) {
    if (value > this.rowsAux[index].valorFactura || value < valueConditional) {
      this.eventPositionError.emit(index);
      this.eventDataSelected.emit(this.rows);
      return true;
    } else {
      this.eventDataSelected.emit(this.rows);
      return false;
    }
  }

  getValidFacture(i, control) {
    return this.valores.controls[i].get(control).invalid;
  }
}
