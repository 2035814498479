<div class="container">
  <div nz-row nzAlign="top" nzJustify="space-between">
    <div nz-col>
      <p class="text-bold text-gray text-smallSpecial">{{ info.method }}</p>
      <p class="text-gray text-smallSpecial">{{ info.bank }}</p>
      <p class="text-gray text-smallSpecial">{{ info.bill }}</p>
    </div>
    <div nz-col>
      <p class="text-bold text-black">
        ${{ valueProduct | currency: " ":"symbol":"1.0-2":"es" }},<span
          class="text-xsmall"
          >{{ valueDec }}</span
        >
      </p>
    </div>
  </div>

  <div class="mt-2" nz-row nzAlign="middle">
    <div nz-col>
      <p (click)="deleteProduct()" class="deleteProduct text-xsmallSpecial">
        Eliminar este artículo
      </p>
    </div>
  </div>
</div>
