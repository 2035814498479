<div *ngIf="apiBusy" nz-row nzAlign="top" nzJustify="center">
  <div nz-col>
    <nz-spin nzSize="large" nzSimple></nz-spin>
  </div>
</div>

<div *ngIf="!apiBusy">
  <div nz-row>
    <p class="text-primary-light text-title text-bold">
      Información General de Agencia
    </p>
  </div>
  <form [formGroup]="formEditAgency">
    <div class="mt-3" nz-row nzAlign="top">
      <div nz-col nzSpan="24">
        <nz-collapse nzAccordion nzExpandIconPosition="right">
          <nz-collapse-panel
            *ngFor="let panel of panels; let i = index"
            [nzHeader]="panel.name"
            [nzActive]="panel.active"
          >
            <div *ngIf="panel.id == 0">
              <div nz-row nzAlign="top" nzGutter="23">
                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Nombre del Representante Legal *</small>
                  <input
                    nz-input
                    formControlName="legalPersonName"
                    placeholder=""
                  />
                  <div
                    class="absolute"
                    *ngIf="
                      f.legalPersonName.invalid && f.legalPersonName.touched
                    "
                  >
                    <small
                      *ngIf="f.legalPersonName.errors?.required"
                      class="text-danger"
                      >Este campo es obligatorio</small
                    >
                  </div>
                </div>
                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Documento de Identificación *</small>
                  <div nz-row nzAlign="middle" nzGutter="23">
                    <div nz-col nzSpan="9" class="gutter-row">
                      <nz-select
                        (ngModelChange)="detectedDocType($event)"
                        formControlName="typeDocId"
                        nzPlaceHolder=""
                        nzAllowClear
                      >
                        <nz-option
                          *ngFor="let item of listTypeDocument"
                          [nzLabel]="item.code"
                          [nzValue]="item.id"
                        >
                        </nz-option>
                      </nz-select>
                    </div>
                    <div nz-col nzSpan="15" class="gutter-row">
                      <input
                        nz-input
                        formControlName="legalPersonDocId"
                        placeholder=""
                      />
                    </div>
                    <div
                      class="absolute-document"
                      *ngIf="
                        (f.legalPersonDocId.invalid &&
                          f.legalPersonDocId.touched) ||
                        f.typeDocId.invalid
                      "
                    >
                      <small
                        *ngIf="
                          f.legalPersonDocId.errors?.required ||
                          f.typeDocId.invalid
                        "
                        class="text-danger"
                        >Este campo es obligatorio</small
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4" nz-row nzAlign="top" nzGutter="23">
                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Fecha de Nacimiento *</small>
                  <div>
                    <nz-date-picker
                      [nzInputReadOnly]="true"
                      formControlName="fecha"
                      [nzDisabledDate]="disabledDate"
                      [nzFormat]="'dd/MM/yyyy'"
                      nzPlaceHolder=""
                      class="w-100"
                      (ngModelChange)="getDate($event)"
                      (nzOnOpenChange)="detectedDate($event)"
                    ></nz-date-picker>
                  </div>
                  <div class="absolute">
                    <small *ngIf="!validDate" class="text-danger"
                      >Debe ser mayor de edad</small
                    >
                    <small *ngIf="errorDate" class="text-danger"
                      >Este campo es obligatorio</small
                    >
                  </div>
                </div>

                <div nz-col nzSpan="12" class="gutter-row">
                  <small>Sexo *</small>
                  <nz-select
                    formControlName="sexo"
                    (ngModelChange)="detectedSexo($event)"
                    nzPlaceHolder=""
                    nzAllowClear
                  >
                    <nz-option
                      *ngFor="let item of listSexo"
                      [nzLabel]="item.genderName"
                      [nzValue]="item.id"
                    >
                    </nz-option>
                  </nz-select>
                  <div
                    class="absolute"
                    *ngIf="f.sexo.invalid && f.sexo.touched"
                  >
                    <small *ngIf="f.sexo.errors?.required" class="text-danger"
                      >Este campo es obligatorio</small
                    >
                  </div>
                </div>
              </div>

              <div class="mt-3" nz-row nzAlign="top" nzGutter="23">
                <div nz-col nzSpan="12">
                  <small> Teléfono</small>
                  <input
                    nz-input
                    maxlength="10"
                    formControlName="phone2"
                    placeholder=""
                  />
                  <div
                    class="absolute"
                    *ngIf="f.phone2.invalid && f.phone2.touched"
                  >
                    <small
                      *ngIf="
                        f.phone2.errors?.pattern || f.phone2.errors?.minlength
                      "
                      class="text-danger"
                      >Teléfono no válido</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="panel.id == 1">
              <div nz-row nzAlign="top" nzGutter="23">
                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Nombre de comercio *</small>
                  <input
                    nz-input
                    formControlName="comercialName"
                    placeholder=""
                  />
                  <div
                    class="absolute"
                    *ngIf="f.comercialName.invalid && f.comercialName.touched"
                  >
                    <small
                      *ngIf="f.comercialName.errors?.required"
                      class="text-danger"
                      >Este campo es obligatorio</small
                    >
                  </div>
                </div>

                <div nz-col nzSpan="12" class="gutter-row">
                  <small> NIT *</small>
                  <input nz-input formControlName="nit" placeholder="" />
                  <div class="absolute" *ngIf="f.nit.invalid && f.nit.touched">
                    <small *ngIf="f.nit.errors?.required" class="text-danger"
                      >Este campo es obligatorio</small
                    >
                  </div>
                </div>
              </div>

              <div class="mt-3" nz-row nzAlign="top" nzGutter="23">
                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Teléfono *</small>
                  <input
                    nz-input
                    maxlength="10"
                    formControlName="phone"
                    placeholder=""
                  />
                  <div
                    class="absolute"
                    *ngIf="f.phone.invalid && f.phone.touched"
                  >
                    <small *ngIf="f.phone.errors?.required" class="text-danger"
                      >Este campo es obligatorio</small
                    >
                    <small *ngIf="f.phone.errors?.pattern" class="text-danger"
                      >Teléfono no válido</small
                    >
                  </div>
                </div>

                <div nz-col nzSpan="12" class="gutter-row">
                  <small> Correo Electrónico *</small>
                  <input nz-input formControlName="email" placeholder="" />
                  <div
                    class="absolute"
                    *ngIf="f.email.invalid && f.email.touched"
                  >
                    <small *ngIf="f.email.errors?.required" class="text-danger"
                      >Este campo es obligatorio</small
                    >
                    <small *ngIf="f.email.errors?.pattern" class="text-danger"
                      >Formato de correo inválido (ejemplo@mail.com)</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="panel.id == 2">
              <div *ngIf="foundLocation">
                <div nz-row nzAlign="top" nzGutter="23">
                  <div nz-col nzSpan="24" class="gutter-row">
                    <small> Dirección *</small>
                    <input
                      nz-input
                      formControlName="address"
                      placeholder=""
                      (input)="autoCompleteLocation($event)"
                      [nzAutocomplete]="auto"
                      (blur)="selectedLocation()"
                    />
                    <nz-autocomplete
                      [nzDataSource]="listSuggestion"
                      nzBackfill
                      #auto
                    ></nz-autocomplete>
                    <div
                      class="absolute"
                      *ngIf="f.address.invalid && f.address.touched"
                    >
                      <small
                        *ngIf="f.address.errors?.required"
                        class="text-danger"
                        >Este campo es obligatorio</small
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <google-map
                    height="350px"
                    width="100%"
                    [center]="center"
                    [zoom]="zoom"
                    [options]="options"
                    (mapClick)="addMarker($event)"
                  >
                    <map-marker
                      *ngFor="let marker of markers"
                      [position]="marker.position"
                      [label]="marker.label"
                      [title]="marker.title"
                      [options]="marker.options"
                    >
                    </map-marker>
                  </google-map>
                </div>
              </div>

              <div *ngIf="!foundLocation">
                <form [formGroup]="formAddLocation">
                  <div nz-row nzAlign="middle">
                    <div nz-col nzXs="16">
                      <p class="text-bold">Por favor agrega la dirección</p>
                    </div>
                  </div>

                  <div class="mt-2" nz-row nzAlign="middle">
                    <div nz-col nzSpan="16" nzSm="16" nzXs="16">
                      <p>
                        Ha ocurrido un problema ubicando su agencia
                        geográficamente, por favor introduzca la dirección de
                        manera manual
                      </p>
                    </div>
                  </div>

                  <div class="mt-3" nz-row nzAlign="middle">
                    <div nz-col nzSpan="24">
                      <small>Dirección *</small>
                      <input
                        class="mt-1"
                        nz-input
                        (input)="validOtherLocation($event)"
                        formControlName="direccion"
                        [placeholder]="
                          'Av. 8va #11-150 Prados del Este, Cúcuta'
                        "
                      />
                      <div
                        class="absolute"
                        *ngIf="
                          fAddLocation.direccion.invalid &&
                          fAddLocation.direccion.touched
                        "
                      >
                        <small
                          *ngIf="fAddLocation.direccion.errors?.required"
                          class="text-danger"
                          >Este campo es obligatorio</small
                        >
                        <small
                          *ngIf="fAddLocation.direccion.errors?.pattern"
                          class="text-danger"
                          >Dirección inválida</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23">
                    <div
                      nz-col
                      class="gutter-row"
                      nzSpan="12"
                      nzSm="12"
                      nzXs="24"
                    >
                      <small>Departamento *</small>
                      <nz-select
                        class="mt-1"
                        formControlName="departamento"
                        [nzPlaceHolder]="'Seleccione el departamento'"
                        (ngModelChange)="detectedChangeDepart($event)"
                      >
                        <nz-option
                          *ngFor="let item of listDepart"
                          [nzLabel]="item.description"
                          [nzValue]="item"
                        >
                        </nz-option>
                      </nz-select>
                      <div
                        class="absolute"
                        *ngIf="
                          fAddLocation.departamento.invalid &&
                          fAddLocation.departamento.touched
                        "
                      >
                        <small
                          *ngIf="fAddLocation.departamento.errors?.required"
                          class="text-danger"
                          >Este campo es obligatorio</small
                        >
                      </div>
                    </div>
                    <div
                      nz-col
                      class="gutter-row"
                      nzSpan="12"
                      nzSm="12"
                      nzXs="24"
                    >
                      <small>Ciudad *</small>
                      <nz-select
                        class="mt-1"
                        nzPlaceHolder="Seleccione la ciudad"
                        [nzPlaceHolder]="'Seleccione la ciudad'"
                        formControlName="ciudad"
                      >
                        <nz-option
                          *ngFor="let item of listCity"
                          [nzLabel]="item.description"
                          [nzValue]="item"
                        >
                        </nz-option>
                      </nz-select>
                      <div
                        class="absolute"
                        *ngIf="
                          fAddLocation.ciudad.invalid &&
                          fAddLocation.ciudad.touched
                        "
                      >
                        <small
                          *ngIf="fAddLocation.ciudad.errors?.required"
                          class="text-danger"
                          >Este campo es obligatorio</small
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div *ngIf="panel.id == 3">
              <div class="content-img">
                <div
                  *ngFor="
                    let pic of apiAgencia.infoGeneral?.agencyPictures;
                    let i = index
                  "
                >
                  <div
                    (mouseover)="imgEditOver(i)"
                    (mouseleave)="imgEditLeave()"
                    (click)="open(i, uploader)"
                    class="section-img"
                  >
                    <img
                      [ngStyle]="{
                        opacity: viewIcon && i === positionEditImg ? '0.5' : ''
                      }"
                      [src]="pic.picture_url"
                      alt=""
                    />
                    <input
                      type="file"
                      #uploader
                      (change)="fileChangeEvent($event.target.files, i)"
                    />
                    <i
                      *ngIf="viewIcon && i === positionEditImg"
                      (mouseover)="imgEditOver(i)"
                      (mouseleave)="imgEditLeave()"
                      class="fas fa-pen"
                    ></i>
                    <div *ngIf="errorFile && indexPosition === i">
                      <small class="text-danger">{{ msjErrorFile }}</small>
                    </div>
                    <div class="pic-description">
                      <small class="text-bold text-title">{{
                        pic.picture_label
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
        <div class="mt-4" nz-row nzAlign="top" nzJustify="space-around">
          <div nz-col class="gutter-row">
            <button (click)="cancelEditAgency()" class="btn btn-outline-danger">
              Cancelar
            </button>
          </div>
          <div nz-col class="gutter-row">
            <button
              (click)="openModal(agencyEdit, closeIcon)"
              [disabled]="!formEditAgency.valid || !validDate"
              class="btn btn-secondary"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- MODALES -->

<nz-modal
  [(nzVisible)]="isVisible"
  [nzCloseIcon]="closeIcon"
  [nzCancelText]="null"
  [nzOkText]="null"
  (nzOnCancel)="closeModal()"
  [nzWidth]="600"
>
  <ng-container *nzModalContent>
    <div class="container-modal">
      <div class="icon-modal">
        <div class="icon-alert">
          <i class="fas fa-exclamation-circle"></i>
        </div>
      </div>

      <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
        <div nz-col nzSpan="14">
          <p class="text-bold text-black text-center text-medium">
            No hemos podido encontrar tu ubicación
          </p>
        </div>
      </div>

      <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
        <div nz-col>
          <p>¿Que deseas hacer?</p>
        </div>
      </div>
      <div
        class="mt-4"
        nz-row
        nzAlign="middle"
        nzJustify="center"
        nzGutter="30"
      >
        <div nz-col class="gutter-row">
          <button (click)="otherLocation()" class="btn btn-outline-primary">
            Ingresar otra ubicación
          </button>
        </div>

        <div nz-col class="gutter-row">
          <button (click)="closeModal()" class="btn btn-primary">
            Volver a intentar
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>

<ng-template #agencyEdit let-ref="modalRef">
  <div class="container-modal">
    <div nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p
          class="text-bold text-medium text-primary-light text-title text-center"
        >
          Confirmación de edición de agencia
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p class="text-center">¿Está seguro que desea editar esta agencia?</p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center" nzGutter="23">
      <div nz-col class="gutter-row">
        <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
          Cancelar
        </button>
      </div>
      <div nz-col class="gutter-row">
        <button
          class="btn btn-secondary"
          (click)="editAgency(confEditAgency, ref, closeIcon)"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confEditAgency let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p
        class="text-bold text-medium text-primary-light text-title text-center"
      >
        Confirmación de edición de agencia
      </p>
    </div>
  </div>
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">La agencia ha sido editada satisfactoriamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeEditAgency(ref)" class="btn btn-secondary">
        Aceptar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</ng-template>
