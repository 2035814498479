import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginCoguasimalesService } from 'projects/coguasimales/src/app/auth/services/login-coguasimales.service';
import { LoginService } from 'projects/agencia/src/app/auth/services/login.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(
    public route: Router,
    public _route: ActivatedRoute,
    public apiLoginCoguasimales: LoginCoguasimalesService,
    public apiLoginAgencia: LoginService
  ) {}
  pathLogout;
  ngOnInit(): void {
    this._route.paramMap.subscribe((params) => {
      if (params.get('id')) {
        this.pathLogout = params.get('id');
      }
    });
  }

  toLogin() {
    if (this.pathLogout === 'coguasimales') {
      this.apiLoginCoguasimales.logoutCoguasimales().subscribe(
        (resp) => {
          console.log(resp);
          localStorage.removeItem('token');
          this.route.navigate(['/loginCoguasimales']);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.apiLoginAgencia.logout().subscribe(
        (resp) => {
          console.log(resp);
          localStorage.removeItem('token');
          this.route.navigate(['/login']);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
