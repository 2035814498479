import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/coguasimales/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  seletedBank;
  selectedConsig;
  _baseURL: string = environment.base;
  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getListBankStatus(): Observable<any> {
    const url = `${this._baseURL}/coguasimales/transactions/bank-admin/status`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListBank(params): Observable<any> {
    const url = `${this._baseURL}/coguasimales/transactions/bank-admin/`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  editBank(idBank, body): Observable<any> {
    const url = `${this._baseURL}/coguasimales/transactions/bank-admin/edit/${idBank}`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  newBank(body): Observable<any> {
    const url = `${this._baseURL}/coguasimales/transactions/bank-admin/new`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
}
