<div class="container">
  <div class="container-info">
    <div class="content-img">
      <div class="info">
        <p class="text-black text-title text-bold text-xlarge">
          ¡Cerrar Agencia!
        </p>
        <p
          class="mt-1 text-primary text-title text-bold text-right text-medium"
        >
          Gestión de cierre
        </p>
      </div>
    </div>

    <div class="content-datos">
      <div
        *ngIf="apiAgencia.detalleAgencia?.agencyTypeId === 2"
        class="corner-icon premium"
      >
        <i class="fas fa-trophy"></i>
      </div>

      <div
        *ngIf="apiAgencia.detalleAgencia?.agencyTypeId === 2"
        class="corner-text"
      >
        <p class="text-bold text-title">Agencia Premium</p>
      </div>

      <p>
        Ingresa la siguiente información para gestionar el cierre de la agencia
      </p>

      <form [formGroup]="formAgencyCancel">
        <div class="mt-5" nz-row nzAlign="top">
          <div nz-col nzSpan="20">
            <label>Motivo de cierre <span class="text-danger">*</span></label>
            <nz-select
              formControlName="reason"
              class="mt-1"
              nzSize="large"
              nzPlaceHolder="Seleccione motivo de cierre"
            >
              <nz-option
                *ngFor="let item of listOptionDecline"
                [nzLabel]="item.description"
                [nzValue]="item.id"
              >
              </nz-option>
            </nz-select>
            <div class="absolute" *ngIf="f.reason.invalid && f.reason.touched">
              <small *ngIf="f.reason.errors?.required" class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>
      </form>

      <div class="mt-4" nz-row nzAlign="top">
        <div nz-col nzSpan="20">
          <label
            >Documentación de cierre <span class="text-danger">*</span></label
          >

          <div class="mt-1 document-adjunt">
            <input
              nz-input
              nzSize="large"
              placeholder="Adjuntar documentación"
            />
            <input
              (change)="fileChangeEvent($event.target.files)"
              #uploader
              type="file"
              name="myImage"
            />
            <i (click)="open(uploader)" class="fas fa-folder-open"></i>
          </div>
          <div class="absolute" *ngIf="msjErrorFile">
            <small [ngClass]="errorFile ? 'text-danger' : 'text-primary'">{{
              msjErrorFile
            }}</small>
          </div>

          <div class="mt-4 section-document">
            <div *ngFor="let item of listFile; let i = index">
              <div class="info-file mt-1">
                <p>
                  {{ item.file.name }} <span>({{ item.size }}k)</span>
                </p>
                <i class="fas fa-times" (click)="deleteFile(item, i)"></i>
              </div>
            </div>
          </div>

          <div class="margin-top-special" nz-row nzAlign="top" nzJustify="end">
            <div nz-col>
              <button
                [disabled]="!formAgencyCancel.valid || listFile.length === 0"
                class="btn btn-contrast-color"
                (click)="confirCancelAgency(agencyCancel, closeIcon)"
              >
                Cerrar Agencia
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODALES -->

<ng-template #agencyCancel let-ref="modalRef">
  <div class="container-modal">
    <div nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p
          class="text-bold text-medium text-primary-light text-title text-center"
        >
          Confirmación de cierre de agencia
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p class="text-center">
          ¿Está seguro que desea cerrar esta agencia? Al hacerlo no podrá
          utilizar ninguna cuenta de usuario ni hacer ninguna operación en
          adelante con la misma
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center" nzGutter="23">
      <div nz-col class="gutter-row">
        <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
          Cancelar
        </button>
      </div>
      <div nz-col class="gutter-row">
        <button
          class="btn btn-secondary"
          (click)="cancelAgency(confCancelAgency, ref, closeIcon)"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confCancelAgency let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p
        class="text-bold text-medium text-primary-light text-title text-center"
      >
        Confirmación de cierre de agencia
      </p>
    </div>
  </div>
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">La agencia ha sido cerrada satisfactoriamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="destroyModal(ref)" class="btn btn-secondary">
        Aceptar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</ng-template>
