<div class="container">
  <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
    <li
      *ngFor="let menu of menuLeft; let i = index"
      nz-menu-item
      [ngClass]="isCollapsed ? 'close' : 'open'"
      [nzSelected]="i === menuSelected"
      [nzDisabled]="!menu.isEnabled"
      (click)="selectMenu(menu)"
    >
      <div class="margin-special">
        <i [class]="menu.icon"></i>
      </div>
      <div class="section-text">
        <p *ngIf="!isCollapsed">{{ menu.label }}</p>
      </div>
    </li>
  </ul>
  <button nz-button class="btn-collapse mt-1" (click)="collapse()">
    <i nz-icon [nzType]="isCollapsed ? 'right' : 'left'"></i>
  </button>
</div>
