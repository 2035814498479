import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-single-select',
  templateUrl: './table-single-select.component.html',
  styleUrls: ['./table-single-select.component.scss'],
})
export class TableSingleSelectComponent implements OnInit {
  @Input() rows;
  @Input() tableHeaders;

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: any[] = [];
  setOfCheckedId = new Set();
  arrayData: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  onCurrentPageDataChange(e): void {
    this.listOfCurrentPageData = e;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );

    this.checked = listOfEnabledData.every((item) => {
      this.setOfCheckedId.has(item.id);
    });
    this.indeterminate =
      listOfEnabledData.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked;
  }

  unsorted() {}

  onItemChecked(index, e, data) {
    this.updateCheckedSet(index, e, data);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean, data) {
    this.arrayData = [];
    this.setOfCheckedId.clear();
    if (checked) {
      this.setOfCheckedId.add(id);
      this.arrayData.push(data);
    } else {
      this.setOfCheckedId.delete(id);
      this.arrayData.splice(id, 1);
    }
    // console.log('Data para trabajar', this.arrayData);
  }
}
