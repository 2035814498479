import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'details-service',
  templateUrl: './details-service.component.html',
  styleUrls: ['./details-service.component.scss'],
})
export class DetailsPackageComponent implements OnInit {
  @Input() title;
  @Input() listFilter;
  tableHeaders = [];
  rows = [];

  constructor() {
    this.rows = new Array(100).fill(0).map((_, index) => ({
      id: index,
      descripcion: 'Minutos ilimitados Claro',
      valorPaquete: '$1.000,00',
      incAdicional: '$0,00',
      vigencia: 'N/A',
    }));

    this.tableHeaders = [
      {
        label: 'Descripción',
      },
      {
        label: 'Valor Paquete',
      },
      {
        label: 'Inc. Adicional',
      },
      {
        label: 'Vigencia',
      },
    ];
  }

  ngOnInit(): void {}

  selectFilter(index) {
    this.listFilter.forEach((element) => {
      element.selected = false;
    });
    this.listFilter[index].selected = true;
  }
}
