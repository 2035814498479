export function validateInputs(forms) {
  for (const i in forms.controls) {
    if (forms.controls[i]) {
      forms.controls[i].markAsDirty();
      forms.controls[i].updateValueAndValidity();
    }
  }
}

export function setFloatToString(value) {
  let valueTransform = value.toString().replace('.', ',');
  return valueTransform;
}

type Obj = Record<string, unknown>;
export function objCompare(obj1: Obj, obj2: Obj): boolean {
  if (!obj1 || !obj2) return false;
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
