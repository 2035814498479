<nz-table
  #rowSelectionTable
  nzShowSizeChanger
  [nzData]="rows"
  [nzShowSizeChanger]="false"
  [nzSimple]="false"
  [nzPageSize]="5"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th *ngIf="isCheckbox"></th>
      <th nzAlign="center" *ngFor="let header of tableHeaders; let i = index">
        <p>{{ header.label }}</p>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{ styleTable: i % 2 === 0 }"
      *ngFor="let dataRow of rowSelectionTable.data; let i = index"
      (click)="selectedRow(dataRow)"
    >
      <td
        *ngIf="isCheckbox"
        [nzChecked]="setOfCheckedId.has(dataRow.id)"
        (nzCheckedChange)="onItemChecked(dataRow.id, $event, dataRow)"
      ></td>

      <td
        nzAlign="center"
        *ngFor="let item of dataRow | keyvalue: unsorted; let first = first"
        [ngClass]="{ first: first }"
      >
        <!-- Pinta los estilos si es un objeto -->
        <div *ngIf="isObject(item.value)">
          <div
            *ngIf="item.value.icono"
            class="icono"
            [ngStyle]="{ color: item.value.color }"
          >
            <i [class]="item.value.icono"></i>
          </div>

          <div *ngIf="!item.value.icono">
            <div
              class="estado"
              [ngStyle]="{
                color: item.value.color,
                'background-color': item.value.background
              }"
            >
              <p>{{ item.value.nombre }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="isObject(item.value) === false">
          {{ item.value }}
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<!-- <i class="fas fa-medal"></i> -->
