import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent implements OnInit {
  @Input() dataError;

  constructor(private modal: NzModalRef, public route: Router) {}

  ngOnInit(): void {}

  closeModal() {
    if (this.dataError.closeSesion) {
      this.destroyModal();
      localStorage.removeItem('token');
      this.route.navigate(['/loginCoguasimales']);
    } else {
      this.destroyModal();
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }
}
