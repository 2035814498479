import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  selected;
  canPremium;
  @Input() menuLink;
  @Input() notifications;
  @Input() loading;
  @Input() type;
  @Output() eventLogout = new EventEmitter<any>();
  @Output() eventProfile = new EventEmitter<any>();
  @Output() eventPremium = new EventEmitter<any>();
  @Output() eventLink = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {
    this.selected = this.menuLink.route.find((x) => x.enable === true);
    this.active(this.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.canPremium = changes.loading?.currentValue;
  }

  active(item) {
    this.selected = item;
  }

  visibleChangeDropdown(e) {
    if (e === true) {
      this.notifications.countUnread = 0;
    }
  }

  logout() {
    this.eventLogout.emit();
  }

  profile() {
    this.eventProfile.emit();
  }

  toPremium() {
    this.eventPremium.emit();
  }

  toLink(link) {
    if (link) {
      this.eventLink.emit(link);
    }
  }
}
