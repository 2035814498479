import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { LoginCoguasimalesService } from 'projects/coguasimales/src/app/auth/services/login-coguasimales.service';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss'],
})
export class ModalMessageComponent implements OnInit {
  @Input() msjResp;

  constructor(
    private modal: NzModalRef,
    public apiLogin: LoginCoguasimalesService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.destroyModal();
  }

  destroyModal(): void {
    this.apiLogin.errorSesion = false;
    this.modal.destroy();
  }
}
