<div class="container position-relative">
  <!-- <div class="icon">
    <i class="fas fa-times-circle"></i>
  </div> -->

  <div class="icon-image">
    <img src="./assets/img/faceSad.svg" alt="" />
  </div>

  <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-bold text-medium text-title text-primary">
        Algo ha ocurrido
      </p>
    </div>
  </div>

  <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">{{ dataError.text }}</p>
    </div>
  </div>

  <div nz-row class="mt-3" nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button class="btn btn-secondary" (click)="closeModal()">Aceptar</button>
    </div>
  </div>
  <div (click)="closeModal()" class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</div>
