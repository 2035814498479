import { SesionCoguasimalesGuard } from './guards/sesion-coguasimales.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from 'src/app/error/error/error.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [SesionCoguasimalesGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'loginCoguasimales',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'agencia',
    loadChildren: () =>
      import('./agencia/agencia.module').then((m) => m.AgenciaModule),
  },
  {
    path: 'error/:id',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
