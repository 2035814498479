import { CajaService } from './../../../../projects/agencia/src/app/caja/services/caja.service';
import { Component, OnInit } from '@angular/core';
import { CalculatePurchaseService } from 'projects/agencia/src/app/caja/services/calculate-purchase.service';

@Component({
  selector: 'purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.scss'],
})
export class PurchaseSummaryComponent implements OnInit {
  listDataProduct = [];
  subTotal: any;
  subTotalDec;
  iva: any;
  ivaDec;
  descuentos: any;
  descuentosDec;
  total1: number;
  total2: number;
  total: number;
  totalDec;
  constructor(
    public apiCalculatePurchase: CalculatePurchaseService,
    public apiCaja: CajaService
  ) {
    this.listDataProduct = [
      {
        method: 'Depósito en Efectivo',
        bank: 'Grupo Aval - Banco Occidente',
        bill: 'Cta. Ahorros',
        total: 250000,
      },
      {
        method: 'Depósito en Efectivo',
        bank: 'Grupo Aval - Banco Occidente',
        bill: 'Cta. Ahorros',
        total: 250000,
      },
      {
        method: 'Depósito en Efectivo',
        bank: 'Grupo Aval - Banco Occidente',
        bill: 'Cta. Ahorros',
        total: 250000,
      },
      {
        method: 'Depósito en Efectivo',
        bank: 'Grupo Aval - Banco Occidente',
        bill: 'Cta. Ahorros',
        total: 250000,
      },
    ];
  }

  ngOnInit(): void {
    this.loadData();

    this.recalculateProduct();
    this.apiCalculatePurchase.addProduct.subscribe((resp) => {
      this.listDataProduct.push(resp);
      localStorage.setItem(
        'listDataProduct',
        JSON.stringify(this.listDataProduct)
      );
      this.recalculateProduct();
    });
  }

  loadData() {
    if (localStorage.getItem('listDataProduct')) {
      this.listDataProduct = JSON.parse(
        localStorage.getItem('listDataProduct')
      );
    } else {
      localStorage.setItem(
        'listDataProduct',
        JSON.stringify(this.listDataProduct)
      );
    }
  }

  recalculateProduct() {
    this.iva = 0;
    this.subTotal = 0;
    this.total1 = 0;
    this.total2 = 0;
    this.descuentos = 0;
    let monto = 0;
    this.listDataProduct.forEach((element) => {
      this.subTotal = this.subTotal + element.total;
    });

    if (this.descuentos) {
      monto = this.subTotal - this.descuentos;
      this.iva = monto * 0.19;
      this.total2 = monto + this.iva;
      this.total = this.total2;
    } else {
      this.iva = this.subTotal * 0.19;
      this.total1 = this.subTotal + this.iva;
      this.total = this.total1;
    }

    this.subTotalDec = this.transformValueToDec(this.subTotal);
    this.ivaDec = this.transformValueToDec(this.iva);
    this.descuentosDec = this.transformValueToDec(this.descuentos);
    this.totalDec = this.transformValueToDec(this.total);

    this.subTotal = Math.trunc(this.subTotal);
    this.iva = Math.trunc(this.iva);
    this.descuentos = Math.trunc(this.descuentos);
    this.total = Math.trunc(this.total);
  }

  deleteProductEvent(index) {
    this.listDataProduct.splice(index, 1);
    localStorage.setItem(
      'listDataProduct',
      JSON.stringify(this.listDataProduct)
    );
    this.recalculateProduct();
  }

  transformValueToDec(value) {
    let valueDec;

    if (value % 1 === 0) {
      valueDec = '00';
    } else {
      valueDec = value.toString().split('.')[1].substring(0, 2);
    }

    return valueDec;
  }

  finishPay() {
    this.apiCaja.isFinishPay = true;
  }
}
