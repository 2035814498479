import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { objCompare } from 'src/functions/common-functions';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    console.log(file);

    // debugger;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'file-drag',
  templateUrl: './file-drag.component.html',
  styleUrls: ['./file-drag.component.scss'],
})
export class FileDragComponent implements OnInit {
  @Input() data;
  @Input() index;
  @Input() viewData;
  @Input() iconType;
  @Input() FileLoad;
  @Input() nzShowUploadList;
  @Output() sendListDocument = new EventEmitter<any>();
  fileType: string = '.doc,docx,.pdf,rar,.xlsx,.xls,.png'; //file type
  listFile = [];

  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(private msg: NzMessageService) {}

  ngOnInit(): void {
    if (this.FileLoad) this.listFile = this.FileLoad;
  }

  beforeUploadProduct = (file: any) =>
    new Observable((observer: Observer<boolean>) => {
      let formValid = this.data.format.toLowerCase();
      let format = file.name.split('.').pop().toLowerCase();

      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      let maxSize = this.data.maxSizeMb;
      if (!formValid.includes(format)) {
        this.msg.error(`Solo permite formato ${formValid}`);
        observer.complete();
        return;
      }

      if (parseFloat(sizeInMB) > maxSize) {
        this.msg.error(
          `${file.name} El tamaño máximo por documento es ${maxSize} MB.`
        );
        observer.complete();
        return;
      }
      this.listFile = [];
      this.msg.success(`${file.name} Archivo cargado exitosamente.`);
    });

  deletedFile(index) {
    this.listFile.splice(index, 1);

    this.sendListDocument.emit({
      listFile: this.listFile,
      indice: this.index,
    });
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    // console.log(file);
    // debugger;

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  checkFileList(file) {
    if (this.listFile.some((x) => objCompare(x, file))) {
      return false;
    }
    return true;
  }

  onChange(data) {
    // console.log(data);
    // debugger;
    if (data.fileList.length > 0) {
      if (this.checkFileList(data.file)) {
        // let newFile = { ...data.file, status: 'done' };
        // console.log(newFile, 'entro newfile');
        // this.listFile = [...this.listFile, newFile];
      }

      let formValid = this.data.format.toLowerCase();
      let format = data.file.name?.split('.').pop().toLowerCase();
      let sizeInMB = (data.file.size / (1024 * 1024)).toFixed(2);
      let maxSize = this.data.maxSizeMb;
      if (!formValid.includes(format)) {
        this.msg.error(`Solo permite formato ${formValid}`);
        this.listFile = [];
        // this.listFile[0].status = 'error';
        return;
      }

      if (parseFloat(sizeInMB) > maxSize) {
        this.msg.error(
          `${data.file.name} El tamaño máximo por documento es ${maxSize} MB.`
        );
        this.listFile = [];
        // this.listFile[0].status = 'error';
        return;
      }

      this.listFile[0].status = 'done';
      this.listFile[0].file = this.listFile[0].originFileObj;
      this.listFile[0].id = this.data.fieldId.toString();

      this.sendListDocument.emit({
        listFile: this.listFile,
        indice: this.index,
      });

      this.msg.success(`${data.file.name} Archivo cargado exitosamente.`);
    } else {
      this.sendListDocument.emit({
        listFile: [],
        indice: this.index,
      });
    }

    // debugger;
  }

  customRequest = async (file: NzUploadFile): Promise<void> => {};
}
