import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleAgencia } from 'projects/coguasimales/src/app/agencia/detalle-agencia/model/detalleAgencia';

@Component({
  selector: 'info-detalle-agencia',
  templateUrl: './info-detalle-agencia.component.html',
  styleUrls: ['./info-detalle-agencia.component.scss'],
})
export class InfoDetalleAgenciaComponent implements OnInit {
  @Input() detalleAgencia: DetalleAgencia;
  @Input() idAgencia;

  markers = [];
  foundLocation: boolean = true;
  zoom = 12;
  center = { lat: 7.89391, lng: -72.50782 };
  options: google.maps.MapOptions = {
    disableDefaultUI: true,
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };

  menu = [];

  constructor(public route: Router, public _router: ActivatedRoute) {
    this.markers[0] = {
      position: {
        lat: 7.89391,
        lng: -72.50782,
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: {
        icon: '/assets/img/locationRed.svg',
      },
    };

    this.menu = [
      {
        name: 'Ficha de Información General',
        icono: 'fas fa-bars',
        notificacion: 3,
        active: true,
        route: 'ficha-general',
      },
      {
        name: 'Documentación y Contratos',
        icono: 'fas fa-file-alt',
        notificacion: null,
        active: false,
        route: 'ficha-general',
      },
      {
        name: 'Transacciones y Balance',
        icono: 'fas fa-chart-bar',
        notificacion: null,
        active: false,
        route: 'ficha-general',
      },
      {
        name: 'Gestión de Usuarios',
        icono: 'fas fa-users',
        notificacion: null,
        active: false,
        route: `gestion-usuario`,
      },
      {
        name: 'Indicadores y Reportes',
        icono: 'fas fa-chart-pie',
        notificacion: null,
        active: false,
        route: 'ficha-general',
      },
    ];
  }

  ngOnInit(): void {
    let route = window.location.href.split('/');
    let indexActive = this.menu.findIndex((x) => x.route === route[6]);

    this.menu.forEach((element) => {
      element.active = false;
    });
    this.menu[indexActive].active = true;
  }

  selected(index) {
    this.menu.forEach((element) => {
      element.active = false;
    });
    this.menu[index].active = true;
    this.route.navigate([this.menu[index].route], { relativeTo: this._router });
  }
}
