<div class="section-actions">
  <div class="item-action" (click)="eventAction('edit')">
    <i title="Editar" class="fas fa-edit"></i>
  </div>
  <div class="item-action" (click)="eventAction('block')">
    <i title="Bloquear" class="fas fa-ban"></i>
  </div>
  <div class="item-action" (click)="eventAction('cancel')">
    <i title="Cerrar" class="fal fa-times-circle"></i>
  </div>
</div>
