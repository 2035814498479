<div *ngIf="deviceWidth > 1180" class="container">
  <div class="step" *ngFor="let step of steppers; let i = index">
    <i *ngIf="step.touched" class="fas fa-check-circle"></i>
    <div *ngIf="!step.touched && !step.active" class="noTouched">
      <p class="text-bold text-medium">{{ i + 1 }}</p>
    </div>
    <div *ngIf="!step.touched && step.active" class="active">
      <p class="text-bold text-medium">{{ i + 1 }}</p>
    </div>
    <div [ngClass]="step.active === true ? 'label-active' : 'label-normal'">
      <p>{{ step.nombre }}</p>
    </div>
    <div *ngIf="i < steppers.length - 1" class="border-step"></div>
  </div>
</div>

<div *ngIf="deviceWidth <= 1180" class="container">
  <div class="step" *ngFor="let step of steppers; let i = index">
    <div *ngIf="i === 0" class="border-first"></div>
    <i *ngIf="step.touched" class="fas fa-check-circle"></i>
    <div *ngIf="!step.touched && !step.active" class="noTouched">
      <p class="text-bold text-medium">{{ i + 1 }}</p>
    </div>
    <div *ngIf="!step.touched && step.active" class="active">
      <p class="text-bold text-medium">{{ i + 1 }}</p>
    </div>
    <div [ngClass]="step.active === true ? 'label-active' : 'label-normal'">
      <p *ngIf="deviceWidth > 786 || step.active">{{ step.nombre }}</p>
    </div>
    <div *ngIf="i < steppers.length - 1" class="border-step"></div>
    <div *ngIf="i === steppers.length - 1" class="border-last"></div>
  </div>
</div>
