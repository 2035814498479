import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { GestionUsuarioService } from 'projects/coguasimales/src/app/agencia/detalle-agencia/gestion-usuario/services/gestion-usuario.service';
import { ProfileService } from 'projects/coguasimales/src/app/auth/services/profile.service';

@Component({
  selector: 'app-acount-profile',
  templateUrl: './acount-profile.component.html',
  styleUrls: ['./acount-profile.component.scss'],
})
export class AcountProfileComponent implements OnInit {
  equalsPassword: boolean = true;
  formEditUser: FormGroup;
  listRoles = [];
  listSexo = [];
  dateString;
  validDate: boolean;
  msjSuccess;
  edad: number;
  @Input() data;
  constructor(
    private modal: NzModalRef,
    public modalService: NzModalService,
    public fb: FormBuilder,
    public apiProfile: ProfileService,
    public apiGestionUsuario: GestionUsuarioService,
    public datePipe: DatePipe
  ) {
    this.listRoles = [
      {
        roleId: 1,
        description: 'Option1',
      },
      {
        roleId: 2,
        description: 'Option2',
      },
    ];

    this.formRecoveryPassword = this.fb.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$'),
        ],
      ],
      confirPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$'),
        ],
      ],
    });

    this.formEditUser = this.fb.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      sexo: ['', Validators.required],
      fecha: [''],
      rol: ['', Validators.required],
    });
  }

  formRecoveryPassword: FormGroup;

  ngOnInit(): void {
    this.loadData();
  }

  get fRecovery() {
    return this.formRecoveryPassword.controls;
  }

  get fEditUser() {
    return this.formEditUser.controls;
  }

  loadData() {
    this.apiProfile.getListRolAdmin().subscribe(
      (resp) => {
        this.listRoles = resp;
      },
      (error) => {
        console.log(error);
      }
    );

    this.apiGestionUsuario.getListSex().subscribe(
      (resp) => {
        this.listSexo = resp;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  closeModal() {
    this.destroyModal();
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  destroyModalRef(id) {
    id.destroy();
  }

  iniFormUser() {
    this.fEditUser.name.setValue(this.data.fullName);
    this.fEditUser.email.setValue(this.data.email);
    this.fEditUser.sexo.setValue(this.data.sex.id);
    this.fEditUser.rol.setValue(this.data.role[0].id);
    this.fEditUser.fecha.setValue(this.data.dateOfBirth);
    this.dateString = this.data.dateOfBirth
      .split('/')
      .reverse()
      .join('/')
      .replaceAll('-', '/');
    this.validAge(this.data.dateOfBirth);
  }

  getDate(e) {
    if (e) {
      this.dateString = this.datePipe.transform(e, 'yyyy-MM-dd');

      var newdate = this.dateString
        .split('/')
        .reverse()
        .join('/')
        .replaceAll('-', '/');
      this.validAge(newdate);
    }
  }

  validAge(date) {
    this.edad = this.calculateAge(date);
    if (this.edad < 18) {
      this.validDate = false;
    } else {
      this.validDate = true;
    }
  }

  calculateAge(fecha_nacimiento) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var birthDate = new Date(fecha_nacimiento);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  editAcountProfile(modal, closeIcon) {
    this.destroyModal();
    this.iniFormUser();
    this.modalService.create({
      nzCancelText: null,
      nzOkText: null,
      nzFooter: null,
      nzWidth: 440,
      nzStyle: { top: '40px' },
      nzCloseIcon: closeIcon,
      nzContent: modal,
    });
  }

  resetPassword(modal, closeIcon) {
    this.destroyModal();
    this.modalService.create({
      nzCancelText: null,
      nzOkText: null,
      nzFooter: null,
      nzWidth: 440,
      nzCloseIcon: closeIcon,
      nzContent: modal,
    });
  }

  confirmPassword(e) {
    if (
      this.fRecovery.newPassword.value === this.fRecovery.confirPassword.value
    ) {
      this.equalsPassword = true;
    } else {
      this.equalsPassword = false;
    }
  }

  closeModalRef(id) {
    this.destroyModalRef(id);
  }

  openModalRef(modal, id, closeIcon) {
    let body = {
      newPassword: this.fRecovery.newPassword.value,
      repeatPassword: this.fRecovery.confirPassword.value,
      email: this.data.email,
    };

    this.apiProfile.changePasswordProfile(body).subscribe(
      (resp) => {
        this.destroyModalRef(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzWidth: 440,
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
      },
      (error) => {
        this.destroyModalRef(id);
        console.log(error);
      }
    );
  }

  validName(e) {
    e.target.value = e.target.value.replace(/[^A-Za-zÁáéíóúÉÍÓÚñÑ ]/, '');
  }

  confirEdit(id, modal, closeIcon) {
    let rol = [];
    rol.push(this.fEditUser.rol.value);

    let date = this.dateString
      .split('-')
      .reverse()
      .join('-')
      .replaceAll('-', '/');

    let body = {
      fullName: this.fEditUser.name.value,
      email: this.fEditUser.email.value,
      dateOfBirth: date,
      sex: this.fEditUser.sexo.value,
      role: rol,
    };

    this.apiProfile.editProfile(body).subscribe(
      (resp) => {
        this.msjSuccess = 'El usuario se actualizó correctamente';
        this.destroyModalRef(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzWidth: 440,
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
      },
      (error) => {
        console.log(error);
        this.destroyModalRef(id);
      }
    );
  }
}
