import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AceptTermsModalComponent } from '../acept-terms-modal/acept-terms-modal.component';
import { AgenciaPremiumModalComponent } from '../agencia-premium-modal/agencia-premium-modal.component';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
})
export class GeneralModalComponent implements OnInit {
  @Input() description: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() spin: boolean;
  @Input() buttonPrimary;
  @Input() buttonOutline;
  @Input() aprobado;
  @Input() solicitRecibida;
  apiBusyModal: boolean;
  constructor(
    private modal: NzModalRef,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.apiBusyModal = this.spin;
    if (this.spin) {
      setTimeout(() => {
        this.apiBusyModal = false;
        this.destroyModal();

        //CONFIG PARA MODAL ACEPTERMS
        this.modalService.create({
          nzMaskClosable: false,
          nzCancelText: null,
          nzOkText: null,
          nzClosable: false,
          nzStyle: { top: '10px' },
          nzWidth: 1000,
          nzFooter: null,
          nzBodyStyle: {
            padding: '0 20px',
            color: '#707070',
          },
          nzContent: AceptTermsModalComponent,
        });
      }, 2500);
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  toModalPremium() {
    this.destroyModal();

    this.modalService.create({
      nzMaskClosable: false,
      nzCancelText: null,
      nzOkText: null,
      nzClosable: false,
      nzWidth: 1000,
      nzStyle: { top: '20px' },
      nzFooter: null,
      nzBodyStyle: {
        padding: '20px 86px',
        color: '#707070',
      },
      nzContent: AgenciaPremiumModalComponent,
    });
  }

  closeModal() {
    this.destroyModal();
  }
}
