import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapGeocoder } from '@angular/google-maps';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { differenceInCalendarDays } from 'date-fns';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StatusAgency } from 'projects/coguasimales/src/app/agencia/model/statusAgency';
import { AgenciaService } from 'projects/coguasimales/src/app/agencia/services/agencia.service';

@Component({
  selector: 'form-edit-agency',
  templateUrl: './form-edit-agency.component.html',
  styleUrls: ['./form-edit-agency.component.scss'],
})
export class FormEditAgencyComponent implements OnInit {
  @Input() idAgencia;

  formEditAgency: FormGroup;
  today = new Date();
  dateString;
  edad: number;
  listSexo = [];
  listTypeDocument = [];
  listFile = [];
  address;
  panels = [];
  listSuggestion = [];
  validDate: boolean = true;
  msjErrorFile: string;
  errorFile: boolean;
  errorDate: boolean;
  isColombia: boolean = true;
  markers = [];
  center = { lat: 7.89391, lng: -72.50782 };
  zoom = 12;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 50,
    minZoom: 0,
  };
  isVisible: boolean;
  circleCenter: google.maps.LatLngLiteral = { lat: 10, lng: 15 };
  foundLocation: boolean = true;
  formAddLocation: FormGroup;
  validTextLocation: boolean;
  listCity = [];
  listDepart = [];
  apiBusy: boolean;
  viewIcon: boolean;
  positionEditImg;
  indexPosition;
  imgEditInterior;
  imgEditFrontal;
  imgEditLateral;

  @ViewChild(NzCarouselComponent)
  carouselImage: NzCarouselComponent;

  private autoCompleteServices: google.maps.places.AutocompleteService;

  constructor(
    public fb: FormBuilder,
    public geocoder: MapGeocoder,
    public datePipe: DatePipe,
    public apiAgencia: AgenciaService,
    private modalService: NzModalService,
    private sanitizer: DomSanitizer
  ) {
    this.formAddLocation = this.fb.group({
      direccion: ['', Validators.required],
      departamento: ['', Validators.required],
      ciudad: ['', Validators.required],
    });
    this.panels = [
      {
        active: true,
        name: 'Datos de Representante Legal',
        id: 0,
      },
      {
        active: false,
        name: 'Datos de Comercio',
        id: 1,
      },
      {
        active: false,
        name: 'Dirección del comercio',
        id: 2,
      },
      {
        active: false,
        name: 'Imágenes de fachada',
        id: 3,
      },
    ];

    this.formEditAgency = this.fb.group({
      comercialName: ['', Validators.required],
      nit: ['', Validators.required],
      address: ['', Validators.required],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern('^[3][0-9]{0,10}$'),
        ],
      ],
      phone2: [
        '',
        [Validators.minLength(10), Validators.pattern('^[3][0-9]{0,10}$')],
      ],
      email: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      legalPersonName: ['', Validators.required],
      legalPersonDocId: ['', Validators.required],
      fecha: ['', Validators.required],
      sexo: ['', Validators.required],
      typeDocId: ['', Validators.required],
    });
  }

  get f() {
    return this.formEditAgency.controls;
  }

  get fAddLocation() {
    return this.formAddLocation.controls;
  }

  ngOnInit(): void {
    this.loadData();
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  getDate(e) {
    if (e) {
      this.errorDate = false;
      this.dateString = this.datePipe.transform(e, 'yyyy-MM-dd');
      var newdate = this.dateString
        .split('/')
        .reverse()
        .join('/')
        .replaceAll('-', '/');
      this.edad = this.calculateAge(newdate);
      if (this.edad < 18) {
        this.validDate = false;
      } else {
        this.validDate = true;
      }
    } else {
      this.errorDate = true;
      this.validDate = true;
    }
  }

  calculateAge(fecha_nacimiento) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var birthDate = new Date(fecha_nacimiento);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  detectedDate(e) {
    this.f.fecha.markAllAsTouched();
  }

  loadData() {
    // if (this.apiAgencia.detalleAgencia.phone2) {
    //   this.formEditAgency.addControl(
    //     'phone2',
    //     this.fb.control('', [
    //       Validators.minLength(10),
    //       Validators.pattern('^[3][0-9]{0,10}$'),
    //     ])
    //   );
    // }

    this.apiBusy = true;
    this.apiAgencia.getSexo().subscribe(
      (resp) => {
        this.listSexo = resp;
        this.apiAgencia.getTypeDocument().subscribe(
          (resp) => {
            this.listTypeDocument = resp;
            this.initForm();
            this.apiBusy = false;
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );

    this.apiAgencia.getListDepart().subscribe(
      (resp) => {
        this.listDepart = resp;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  initForm() {
    let setData = {
      comercialName: this.apiAgencia.detalleAgencia.agencyName,
      nit: this.apiAgencia.detalleAgencia.nit,
      address: this.apiAgencia.detalleAgencia.address.address,
      phone: this.apiAgencia.detalleAgencia.phone1,
      phone2: this.apiAgencia.detalleAgencia.phone2
        ? this.apiAgencia.detalleAgencia.phone2
        : '',
      email: this.apiAgencia.detalleAgencia.email,
      legalPersonName:
        this.apiAgencia.infoGeneral.legalPersonDto.nameLegalPerson,
      legalPersonDocId: this.apiAgencia.infoGeneral.legalPersonDto.docIdNumber,
      sexo: this.apiAgencia.infoGeneral.legalPersonDto.genderSex.id,
      fecha: this.apiAgencia.infoGeneral.legalPersonDto.dateOfBirth,
      typeDocId: this.listTypeDocument.find(
        (x) => x.code === this.apiAgencia.infoGeneral.legalPersonDto.docIdCode
      ).id,
    };
    // if (this.apiAgencia.detalleAgencia.phone2) {
    //   setData['phone2'] = this.apiAgencia.detalleAgencia.phone2;
    // }

    this.formEditAgency.setValue(setData);

    let status = this.apiAgencia.detalleAgencia.status.keyStatus;

    if (status === StatusAgency.ACT_ESTANDAR) {
      this.f.comercialName.disable();
      this.f.nit.disable();
    }
  }

  fileChangeEvent(e, index) {
    let formValid = 'jpg,jpeg,png';
    let format = e[0].name.split('.').pop().toLowerCase();

    let sizeInMB = (e[0].size / (1024 * 1024)).toFixed(2);
    let maxSize = 3;

    if (!formValid.includes(format)) {
      this.msjErrorFile = `Solo permite formato JPG,JPEG,PNG`;
      this.errorFile = true;
      return;
    }
    if (parseFloat(sizeInMB) > maxSize) {
      this.msjErrorFile = `Peso máximo permitido 3MB`;
      this.errorFile = true;
      return;
    }

    this.msjErrorFile = '';
    this.errorFile = false;

    this.apiAgencia.infoGeneral.agencyPictures[index].picture_url =
      this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(e[0])
      ) as string;

    if (
      this.apiAgencia.infoGeneral.agencyPictures[index].picture_label.includes(
        'interior'
      )
    ) {
      this.imgEditInterior = e[0];
    }

    if (
      this.apiAgencia.infoGeneral.agencyPictures[index].picture_label.includes(
        'frontal'
      )
    ) {
      this.imgEditFrontal = e[0];
    }

    if (
      this.apiAgencia.infoGeneral.agencyPictures[index].picture_label.includes(
        'lateral'
      )
    ) {
      this.imgEditLateral = e[0];
    }
  }

  openModal(modal, closeIcon) {
    if (!this.isColombia && !this.formAddLocation.valid) {
      this.isVisible = true;
    } else {
      this.modalService.create({
        nzCancelText: null,
        nzOkText: null,
        nzFooter: null,
        nzStyle: {
          top: '25%',
        },
        nzCloseIcon: closeIcon,
        nzContent: modal,
      });
    }
  }

  closeModal() {
    this.isVisible = false;
  }

  otherLocation() {
    this.isVisible = false;
    setTimeout(() => {
      this.foundLocation = false;
    }, 200);
  }

  cancelEditAgency() {
    this.apiAgencia.isAgencyEdit = false;
  }

  destroyModal(id) {
    id.destroy();
  }

  editAgency(modal, id, closeIcon) {
    let city = this.fAddLocation.ciudad.value;
    let depart = this.fAddLocation.departamento.value;
    let cityExist = this.apiAgencia.detalleAgencia.address.city?.id
      ? this.apiAgencia.detalleAgencia.address.city?.id
      : null;
    let departExist = this.apiAgencia.detalleAgencia.address.state?.id
      ? this.apiAgencia.detalleAgencia.address.state?.id
      : null;

    let dateString = this.datePipe.transform(this.f.fecha.value, 'dd-MM-yyyy');

    let body = {
      legalPersonName: this.f.legalPersonName.value,
      commercialName: this.f.comercialName.value,
      nit: this.f.nit.value,
      address: this.f.address.value,
      phone: this.f.phone.value,
      email: this.f.email.value,
      legalPersonDocIdType: this.f.typeDocId.value,
      legalPersonDocId: this.f.legalPersonDocId.value,
      legalPersonGender: this.f.sexo.value,
      legalPersonBirthDate: dateString,
      legalPersonPhone: this.f.phone2.value,
      latitude: this.markers[0]?.position.lat
        ? this.markers[0]?.position.lat
        : this.apiAgencia.detalleAgencia.address.latitude,
      longitude: this.markers[0]?.position.lng
        ? this.markers[0]?.position.lng
        : this.apiAgencia.detalleAgencia.address.longitude,
      stateId: depart.id ? depart.id : departExist,
      cityId: city.id ? city.id : cityExist,
      fotoInterior: this.imgEditInterior ? this.imgEditInterior : null,
      fotoFrontal: this.imgEditFrontal ? this.imgEditFrontal : null,
      fotoLateral: this.imgEditLateral ? this.imgEditLateral : null,
    };

    // if (this.apiAgencia.detalleAgencia.phone2) {
    //   body['legalPersonPhone'] = this.apiAgencia.detalleAgencia.phone2;
    // }

    // console.log(body);

    // debugger;

    this.apiAgencia.editAgency(this.idAgencia, body).subscribe(
      (resp) => {
        console.log(resp);
        this.destroyModal(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzStyle: {
            top: '25%',
          },
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
      },
      (error) => {
        console.log(error);
        this.destroyModal(id);
      }
    );
  }

  closeEditAgency(id) {
    this.destroyModal(id);
    this.apiAgencia.isAgencyEdit = false;
  }

  detectedSexo(data) {
    if (!data) {
      this.f.sexo.markAllAsTouched();
    }
  }

  detectedDocType(data) {
    if (!data) {
      this.f.typeDocId.markAllAsTouched();
    }
  }

  autoCompleteLocation(e) {
    this.autoCompleteServices = new google.maps.places.AutocompleteService();
    this.autoCompleteServices.getPlacePredictions(
      { input: e.target.value },
      (resp) => {
        console.log(resp);
        this.listSuggestion = [];
        if (resp)
          resp.forEach((element) => {
            this.listSuggestion.push(element.description);
          });
      }
    );
  }

  selectedLocation() {
    this.detectedLocation(this.f.address.value);
  }

  detectedLocation(location) {
    this.isColombia = false;
    this.geocoder
      .geocode({
        address: location,
      })
      .subscribe(({ results }) => {
        let location = results[0].geometry.location.toJSON();

        this.markers[0] = {
          position: location,
          title: 'Marker title ' + (this.markers.length + 1),
          options: {
            icon: '/assets/img/locationRed.svg',
          },
        };

        this.center = location;

        results.forEach((element, index) => {
          let findCountry = element.formatted_address.includes('Colombia');

          if (findCountry) {
            this.isColombia = true;
            // if (element.formatted_address === nameCountry) {
            // }
          }
        });

        this.address = results[0].formatted_address;
        this.f.address.setValue(this.address);
      });
  }

  addMarker(event: google.maps.MapMouseEvent) {
    let location = event.latLng.toJSON();
    const nameCountry = 'Colombia';

    this.isColombia = false;
    this.circleCenter = location;
    this.markers[0] = {
      position: {
        lat: location.lat,
        lng: location.lng,
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: {
        icon: '/assets/img/locationRed.svg',
      },
    };

    this.geocoder
      .geocode({
        location: location,
      })
      .subscribe(({ results }) => {
        results.forEach((element, index) => {
          let findCountry = element.types.find((x) => x === 'country');

          if (findCountry) {
            if (element.formatted_address === nameCountry) {
              this.isColombia = true;
            }
          }
        });

        this.address = results[0].formatted_address;
        this.f.address?.setValue(this.address);
      });
  }

  validOtherLocation(e) {
    e.target.value = e.target.value.trimStart();
    if (
      this.fAddLocation.direccion.value === ' ' &&
      this.fAddLocation.direccion.value.length === 1
    ) {
      this.validTextLocation = false;
    } else {
      this.validTextLocation = true;
    }
  }

  detectedChangeDepart(e) {
    this.fAddLocation.ciudad.setValue(null);
    let params = {
      state_id: e.id,
    };

    this.apiAgencia.getListCity(params).subscribe(
      (resp) => {
        this.listCity = resp;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  backLocation() {
    this.foundLocation = true;
  }

  nextDot(index) {
    this.carouselImage.goTo(index);
  }

  addOtherLocation() {
    // this.apiBusy = true;
    // this.msjError = '';
    // let city = this.fAddLocation.ciudad.value;
    // let depart = this.fAddLocation.departamento.value;
    // let body = {
    //   address: this.fAddLocation.direccion.value,
    //   cityId: city.id,
    //   city: city.name,
    //   stateId: depart.id,
    //   state: depart.name,
    // };
  }

  imgEditOver(index) {
    this.viewIcon = true;
    this.positionEditImg = index;
  }

  imgEditLeave() {
    this.viewIcon = false;
    this.positionEditImg = null;
  }

  open(index, uploader) {
    this.indexPosition = index;
    uploader.click();
  }
}
