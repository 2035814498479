import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'table-custom',
  templateUrl: './table-custom.component.html',
  styleUrls: ['./table-custom.component.scss'],
})
export class TableCustomComponent implements OnInit {
  @Input() rows;
  @Input() tableHeaders;
  @Input() isCheckbox;
  @Input() loaded;
  @Input() page;
  @Input() total;
  @Input() textEllipsis;
  @Input() textCenter;
  @Output() sendRowData = new EventEmitter<any>();
  @Output() eventAction = new EventEmitter<any>();
  @Output() pageIndexChange = new EventEmitter<any>();

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: any[] = [];
  setOfCheckedId = new Set();
  arrayData: any[] = [];
  isArray: boolean;
  tooltipOverlayStyle;

  constructor() {
    this.tooltipOverlayStyle = {
      // boder
    };
  }

  ngOnInit(): void {
    console.log('Data tabla custom', this.rows);
  }

  onCurrentPageDataChange(e): void {
    this.listOfCurrentPageData = e;
    this.refreshCheckedStatus();
  }

  onPageIndexChange(e) {
    console.log(e);
    this.pageIndexChange.emit(e);
  }

  onPageSizeChange(e) {
    console.log(e);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );

    this.checked = listOfEnabledData.every((item) => {
      this.setOfCheckedId.has(item.id);
    });
    this.indeterminate =
      listOfEnabledData.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked;
  }

  unsorted() {}

  onItemChecked(index, e, data) {
    this.updateCheckedSet(index, e, data);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean, data) {
    // this.arrayData = [];
    // this.setOfCheckedId.clear();
    if (checked) {
      this.setOfCheckedId.add(id);
      this.arrayData.push(data);
    } else {
      this.setOfCheckedId.delete(id);
      this.arrayData.splice(id, 1);
    }
    // console.log('Data para trabajar', this.arrayData);
  }

  isObject(obj) {
    if (Array.isArray(obj)) {
      this.isArray = true;
    } else [(this.isArray = false)];

    if (obj !== null) return typeof obj === 'object';
  }

  selectedRow(data) {
    this.sendRowData.emit(data);
  }

  actionEmit(data) {
    this.eventAction.emit(data);
  }

  actionEmitArray(data, index) {
    this.eventAction.emit({ data, index });
  }
}
