<div class="container-modal">
  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-bold text-small text-primary-light text-title text-center">
        Confirmación de edición de agencia
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">¿Está seguro que desea editar esta agencia?</p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center" nzGutter="23">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal()">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button class="btn btn-secondary" (click)="confirEditarAgency()">
        Confirmar
      </button>
    </div>
  </div>
</div>
