<div class="nav-bar">
  <div class="logo">
    <img src="/assets/img/Logo.svg" />
    <p class="text-bold text-primary text-medium">PAGO FÁCIL V3</p>
  </div>

  <div *ngIf="loading === undefined">
    <nz-spin nzSize="large" nzSimple></nz-spin>
  </div>

  <div *ngIf="loading !== undefined" class="nav-list">
    <div
      class="item"
      (click)="active(item)"
      [ngClass]="{ active: selected === item }"
      *ngFor="let item of menuLink.route"
    >
      <span (click)="toLink(item.link)" class="text-title">{{
        item.label
      }}</span>
    </div>

    <div
      (click)="toPremium()"
      [ngStyle]="{ display: canPremium === true ? 'flex' : 'none' }"
      class="boton-premium"
    >
      <div class="text-premium">
        <p>Conviertase en:</p>
        <p class="text-bold">Agencia Premium</p>
      </div>
      <i class="fas fa-medal"></i>
    </div>

    <div class="item-icon">
      <nz-badge
        [nzOffset]="[-3, 25]"
        [nzCount]="notifications.countUnread"
        [nzStyle]="{ background: '#ddc91c', color: '#183e8d' }"
      >
        <a
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="notification"
          (nzVisibleChange)="visibleChangeDropdown($event)"
        >
          <i
            *ngIf="type === 'agencia'"
            class="fas fa-shopping-cart text-primary"
          ></i>
          <i *ngIf="type !== 'agencia'" class="fas fa-bell text-primary"></i>
        </a>
        <nz-dropdown-menu #notification="nzDropdownMenu">
          <ul
            *ngFor="let not of notifications.notifications"
            nz-menu
            nzSelectable
          >
            <li nz-menu-item>{{ not.message }}</li>
          </ul>
        </nz-dropdown-menu>
      </nz-badge>
      <div>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="login">
          <i class="fas fa-user-circle"></i>
        </a>

        <nz-dropdown-menu #login="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="profile()" class="option">
              <i class="fal fa-user"></i> Mi perfil
            </li>
            <li nz-menu-item (click)="logout()" class="option">
              <i class="far fa-sign-out"></i> Cerrar Sesión
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div>
