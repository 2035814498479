import { AdministracionService } from './../../../../projects/agencia/src/app/administracion/services/administracion.service';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AdminService } from 'projects/coguasimales/src/app/agencia/admin/services/admin.service';
import { differenceInCalendarDays } from 'date-fns';
import { DatePipe } from '@angular/common';
import { DetalleConsignacion } from 'projects/agencia/src/app/administracion/model/detalle-consignacion';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  @Input() value;
  @Input() listBankStatus;
  @Input() agencyBankList;

  @ViewChild('closeIcon') closeIcon: TemplateRef<any>;
  @ViewChild('createBank') createBank: TemplateRef<any>;
  @ViewChild('imgConsign') imgConsign: TemplateRef<any>;

  formAddBank: FormGroup;
  formEditBank: FormGroup;

  formAddConsig: FormGroup;
  formEditConsig: FormGroup;
  dataBankSelected;
  dataConsigSelected: DetalleConsignacion;
  valueMax;
  body;
  today = new Date();
  dateString;
  msjErrorFile: string;
  errorFile: boolean;
  fileExist: any;
  listBankAccounts = [];
  test = false;
  test1 = false;
  test2 = false;
  test3 = false;
  test4 = false;
  testText = '';
  msjErrorValue = '';
  msjAviso = 'Monto máximo a consignar ';
  consigValid: boolean;
  imgBank: string;

  icon = {
    class: 'fal fa-plus-circle',
    color: '#183E8D',
  };
  msj;
  bankSelected: boolean;
  constructor(
    public fb: FormBuilder,
    private drawerRef: NzDrawerRef,
    private modalService: NzModalService,
    public apiAdminCoguasimales: AdminService,
    public apiAdminAgencia: AdministracionService,
    public datePipe: DatePipe
  ) {
    this.formAddBank = this.fb.group({
      name: ['', Validators.required],
      nit: ['', [Validators.required]],
      estado: ['', Validators.required],
      observacion: [''],
    });

    this.formEditBank = this.fb.group({
      name: ['', Validators.required],
      nit: ['', [Validators.required]],
      estado: ['', Validators.required],
      observacion: [''],
    });

    this.formAddConsig = this.fb.group({
      bank: ['', Validators.required],
      noCuenta: ['', Validators.required],
      fechaConsig: ['', Validators.required],
      noConsig: ['', Validators.required],
      valueConsig: ['', Validators.required],
      observacion: [''],
    });

    this.formEditConsig = this.fb.group({
      bank: ['', Validators.required],
      noCuenta: ['', Validators.required],
      fechaConsig: ['', Validators.required],
      noConsig: ['', Validators.required],
      valueConsig: ['', Validators.required],
      observacion: [''],
    });
  }

  get fAddBank() {
    return this.formAddBank.controls;
  }

  get fEditBank() {
    return this.formEditBank.controls;
  }

  get fAddConsig() {
    return this.formAddConsig.controls;
  }

  get fEditConsig() {
    return this.formEditConsig.controls;
  }

  ngOnInit(): void {
    // this.apiAdminAgencia.detailsConsig.consignmentStatus.description =
    //   'DEVUELTA';
    // this.formEditConsig.valueChanges.subscribe((resp) => {
    //   console.log(this.formEditConsig.controls);
    // });
  }

  closeDrawer() {
    this.drawerRef.close();
  }

  createModal(modal) {
    this.modalService.create({
      nzCancelText: null,
      nzOkText: null,
      nzFooter: null,
      nzStyle: {
        top: '25%',
      },
      nzCloseIcon: this.closeIcon,
      nzContent: modal,
    });
  }

  destroyModal(id) {
    id.destroy();
  }

  openModalRef(modal, id, closeIcon) {
    // this.destroyModal(id);
    // this.modalService.create({
    //   nzCancelText: null,
    //   nzOkText: null,
    //   nzFooter: null,
    //   nzStyle: {
    //     top: '25%',
    //   },
    //   nzCloseIcon: closeIcon,
    //   nzContent: modal,
    // });

    switch (modal._declarationTContainer.localNames[0]) {
      case 'createdConsig':
        this.body = {
          bankId: this.fAddConsig.bank.value,
          bankAccountId: this.fAddConsig.noCuenta.value,
          consignmentDate: this.dateString,
          consignmentValue: this.fAddConsig.valueConsig.value,
          consignmentNumber: this.fAddConsig.noConsig.value,
          voucherImage: this.fileExist,
          consignmentObservation: this.fAddConsig.observacion.value,
        };

        this.apiAdminAgencia.newConsign(this.body).subscribe(
          (resp) => {
            console.log(resp);
            this.destroyModal(id);
            this.modalService.create({
              nzCancelText: null,
              nzOkText: null,
              nzFooter: null,
              nzStyle: {
                top: '25%',
              },
              nzCloseIcon: closeIcon,
              nzContent: modal,
            });
            this.msj = resp.message;
          },
          (error) => {
            console.log(error);
          }
        );

        break;

      case 'editedConsig':
        this.body = {
          bankId: this.fEditConsig.bank.value,
          bankAccountId: this.fEditConsig.noCuenta.value,
          consignmentDate: this.dateString,
          consignmentValue: this.fEditConsig.valueConsig.value,
          consignmentNumber: this.fEditConsig.noConsig.value,
          voucherImage: this.fileExist,
          consignmentObservation: this.fEditConsig.observacion.value,
        };

        this.apiAdminAgencia
          .editConsig(this.body, this.apiAdminAgencia.detailsConsig.id)
          .subscribe(
            (resp) => {
              console.log(resp);
              this.destroyModal(id);
              this.modalService.create({
                nzCancelText: null,
                nzMaskClosable: false,
                nzOkText: null,
                nzFooter: null,
                nzStyle: {
                  top: '25%',
                },
                nzCloseIcon: closeIcon,
                nzContent: modal,
              });
              this.msj = resp.message;
            },
            (error) => {
              console.log(error);
            }
          );
        break;

      case 'createdBank':
        this.body = {
          bankName: this.fAddBank.name.value,
          bankNit: this.fAddBank.nit.value,
          statusid: this.fAddBank.estado.value,
          observation: this.fAddBank.observacion.value,
        };

        this.apiAdminCoguasimales.newBank(this.body).subscribe(
          (resp) => {
            console.log(resp);
          },
          (error) => {
            console.log(error);
          }
        );

        break;

      case 'editedBank':
        this.body = {
          bankName: this.fEditBank.name.value,
          bankNit: this.fEditBank.nit.value,
          statusid: this.fEditBank.estado.value,
          observation: this.fEditBank.observacion.value,
        };

        this.apiAdminCoguasimales
          .editBank(this.dataBankSelected.id, this.body)
          .subscribe(
            (resp) => {
              console.log(resp);
            },
            (error) => {
              console.log(error);
            }
          );
        break;

      case 'rejectedConsig':
        this.destroyModal(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzStyle: {
            top: '25%',
          },
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
        break;

      case 'valideConsig':
        this.destroyModal(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzStyle: {
            top: '25%',
          },
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
        break;

      default:
        break;
    }
  }

  closeAll(id) {
    this.destroyModal(id);
    this.closeDrawer();
    this.apiAdminAgencia.reloadRow$.next(true);
  }

  editIsBank() {
    this.initFormEditBank();
    this.value = 'editBank';
  }

  editIsConsig() {
    this.initFormEditConsig();
    this.value = 'editConsig';
  }

  initFormEditConsig() {
    this.dataConsigSelected = this.apiAdminAgencia.detailsConsig;

    let idBank = this.agencyBankList.find(
      (x) => x.bankName === this.dataConsigSelected.bankName
    ).bankId;

    this.imgBank = this.agencyBankList.find(
      (x) => x.bankId === idBank
    ).consignmentGuide;

    //   let reader = new FileReader() ;

    // let metadata = {
    //   type: 'image/jpeg'
    // };
    // let file = new File([data], "test.jpg", metadata);

    let nameFile =
      this.dataConsigSelected.voucherImage.voucherFileLink.split('/');

    let url = new Blob([this.dataConsigSelected.voucherImage.voucherFileLink]);

    let objectURL = URL.createObjectURL(url);

    let file = new File([objectURL], nameFile[5]);
    this.fileExist = file;

    this.apiAdminAgencia.getListBankAccounts(idBank).subscribe(
      (resp) => {
        console.log(resp);
        this.listBankAccounts = resp;
        this.valueMax = resp[0]?.consignmentMaxValue;

        let bankNumber = this.listBankAccounts.find(
          (x) =>
            x.accountBankNumber === this.dataConsigSelected.bankAccountNumber
        ).accountBankId;

        let valueConsig = this.dataConsigSelected.consignmentValue
          .toString()
          .replace('.', ',');

        let obj = {
          bank: idBank,
          noCuenta: bankNumber,
          fechaConsig: this.dataConsigSelected.consignmentDate
            .split('-')
            .reverse()
            .join('-'),
          noConsig: this.dataConsigSelected.consignmentNumber,
          valueConsig: valueConsig,
          observacion: this.dataConsigSelected.consignmentObservation,
        };

        this.formEditConsig.setValue(obj);
      },
      (error) => {
        console.log(error);
      }
    );

    //
  }

  initFormEditBank() {
    this.dataBankSelected = this.apiAdminCoguasimales.seletedBank;

    let obj = {
      name: this.dataBankSelected.banco,
      nit: this.dataBankSelected.nit,
      estado: this.dataBankSelected.estado.id,
      observacion: '',
    };

    this.formEditBank.setValue(obj);
  }

  validFiled(e, control) {
    e.target.value = e.target.value.trimStart();
    if (
      this.fAddBank[control]?.value === ' ' &&
      this.fAddBank[control]?.value.length === 1
    ) {
      this.fAddBank[control].setErrors({ incorrect: true });
    }

    if (
      this.fEditBank[control]?.value === ' ' &&
      this.fEditBank[control]?.value.length === 1
    ) {
      this.fEditBank[control]?.setErrors({ incorrect: true });
    }

    if (
      this.fAddConsig[control]?.value === ' ' &&
      this.fAddConsig[control]?.value.length === 1
    ) {
      this.fAddConsig[control]?.setErrors({ incorrect: true });
    }

    if (
      this.fEditConsig[control]?.value === ' ' &&
      this.fEditConsig[control]?.value.length === 1
    ) {
      this.fEditConsig[control]?.setErrors({ incorrect: true });
    }
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  getDate(e) {
    if (e) {
      this.dateString = this.datePipe.transform(e, 'dd/MM/yyyy');
    }
  }

  getDateEditConsig(e) {
    if (e) {
      this.dateString = this.datePipe.transform(e, 'dd/MM/yyyy');
    }
  }

  detectedDate(e) {
    this.fAddConsig.fechaConsig.markAllAsTouched();
  }

  getFile(e) {
    let formValid = 'png,jpg,jpeg';
    let format = e.name.split('.').pop().toLowerCase();

    let sizeInMB = (e.size / (1024 * 1024)).toFixed(2);
    let maxSize = 3;

    if (!formValid.includes(format)) {
      this.msjErrorFile = `Solo permite formato PNG,JPG,JPEG`;
      this.errorFile = true;
      return;
    }

    if (parseFloat(sizeInMB) > maxSize) {
      this.msjErrorFile = `Peso máximo permitido 3MB`;
      this.errorFile = true;
      return;
    }

    this.msjErrorFile = '';
    this.errorFile = false;

    this.fileExist = e;
  }

  detectedBank(e) {
    this.imgBank = this.agencyBankList.find(
      (x) => x.bankId === parseInt(e)
    ).consignmentGuide;

    this.fAddConsig?.noCuenta.setValue('');
    this.fEditConsig?.noCuenta.setValue('');

    this.apiAdminAgencia.getListBankAccounts(parseInt(e)).subscribe(
      (resp) => {
        console.log(resp);
        this.listBankAccounts = resp;
        this.bankSelected = true;
        this.valueMax = resp[0]?.consignmentMaxValue;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  viewRecord() {
    this.value = 'recordConsig';
  }

  validateConsig() {
    this.value = 'validConsig';
  }

  downloadConsig(link) {
    if (link) window.open(link, '_blank');
  }

  viewImgConsign() {
    this.modalService.create({
      nzCancelText: null,
      nzOkText: null,
      nzFooter: null,
      nzWidth: 950,
      nzStyle: {
        top: '6%',
      },
      nzCloseIcon: this.closeIcon,
      nzContent: this.imgConsign,
    });
  }

  detectedValueConsigMax(value) {
    this.msjAviso = '';
    let valueClean = value.split('$');
    let valueInt = valueClean[1].split(',');
    let currentValue = valueInt[0].replaceAll('.', '');
    currentValue = parseInt(currentValue);
    if (currentValue > this.valueMax) {
      this.msjErrorValue = 'Monto máximo es de $';
    } else {
      this.msjErrorValue = '';
    }
  }

  validValueConsig(value) {
    this.msjAviso = '';

    let valueClean;
    let currentValue;

    if (value.includes('$')) {
      valueClean = value.split('$')[1].replaceAll('.', '').replace(',', '.');
      currentValue = parseFloat(valueClean);
    } else {
      currentValue = value;
    }

    if (
      currentValue === 0 ||
      (Number.isNaN(currentValue) && parseInt(value) === 0)
    ) {
      this.consigValid = false;
    } else {
      this.consigValid = true;
    }

    if (currentValue > this.valueMax) {
      this.msjErrorValue = 'Monto máximo es de ';
    } else {
      this.msjErrorValue = '';
    }
  }

  cleanMsj() {
    this.msjAviso = '';
  }
}
