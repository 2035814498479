<div class="form-control file-box" *ngIf="!fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  <span *ngIf="fileExist" class="text-over-file">{{ file?.name }} </span>
  <span *ngIf="!fileExist" class="text-over-title">{{ title }}</span>
  <i
    (click)="open(uploader)"
    [ngClass]="[icon ? icon.class : 'fas fa-folder-open']"
    [ngStyle]="{ color: icon.color }"
    class="fas fa-folder-open"
  ></i>
</div>
<div class="form-control file-box" *ngIf="fileExist?.size">
  <input
    (change)="fileChangeEvent($event.target.files)"
    #uploader
    type="file"
    name="myImage"
  />
  <span class="text-over-file">{{ fileExist?.name }} </span>
  <span *ngIf="!fileExist" class="text-over-title">{{ title }}</span>
  <i
    (click)="open(uploader)"
    [ngClass]="[icon ? icon.class : 'fas fa-folder-open']"
    [ngStyle]="{ color: icon.color }"
  ></i>
</div>
