import { CajaService } from './../../../../projects/agencia/src/app/caja/services/caja.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'siderbar-menu',
  templateUrl: './siderbar-menu.component.html',
  styleUrls: ['./siderbar-menu.component.scss'],
})
export class SiderbarMenuComponent implements OnInit {
  @Input() menuLeft;
  @Output() collapseValue = new EventEmitter<any>();
  isCollapsed: boolean;
  menuSelected: number;
  constructor(public route: Router, public apiCaja: CajaService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuLeft?.currentValue) {
      this.menuSelected = changes.menuLeft?.currentValue.findIndex(
        (x) => x.link == this.route.url
      );
      this.apiCaja.optionMenuFunctional$.next({
        isEnable: changes.menuLeft?.currentValue[this.menuSelected].isEnabled,
        isFunctional:
          changes.menuLeft?.currentValue[this.menuSelected].isFunctional,
      });
    }
  }

  ngOnInit(): void {}

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseValue.emit(this.isCollapsed);
  }

  selectMenu(option) {
    this.apiCaja.optionMenuFunctional$.next({
      isEnable: option.isEnabled,
      isFunctional: option.isFunctional,
    });
    if (option.isEnabled) this.route.navigate([option.link]);
  }
}
