export function getFormatedDateAndHours(date) {
  let dateCaputre = date.split(' ');

  let dateFormate = dateCaputre[0].replaceAll('-', '/');

  let time = dateCaputre[1].split(':');
  let hour = parseInt(time[0]);
  let timeFormate;

  if (hour >= 12) {
    hour = hour - 12;
    timeFormate = hour.toString() + ':' + time[1] + ' ' + 'PM';
  } else {
    timeFormate = hour.toString() + ':' + time[1] + ' ' + 'AM';
  }

  return dateFormate + ' ' + timeFormate;
}
