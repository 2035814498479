<div class="container">
  <div nz-row nzAlign="middle">
    <div nz-col>
      <p class="text-title text-bold text-primary">Resumen de compra</p>
    </div>
  </div>

  <div class="list-product">
    <div class="content" *ngFor="let item of listDataProduct; let i = index">
      <div class="mt-4">
        <info-purchase
          [info]="item"
          [index]="i"
          (deleteProductEmitter)="deleteProductEvent($event)"
        ></info-purchase>
      </div>
    </div>
  </div>
</div>

<div class="sectionPay">
  <div class="text-right text-black">
    <p class="text-xsmall">Subtotal</p>
    <p class="mt-1 text-xsmall">IVA</p>
    <p class="mt-1 text-xsmall">Descuentos</p>
    <p class="text-black text-bold text-smallSpecial mt-1">Total a pagar</p>
  </div>
  <div class="text-right text-black">
    <p class="text-xsmall">
      ${{ subTotal | currency : " " : "symbol" : "1.0-2" : "es" }},<span
        class="text-xsmallSpecial"
        >{{ subTotalDec }}</span
      >
    </p>
    <p class="mt-1 text-xsmall">
      ${{ iva | currency : " " : "symbol" : "1.0-2" : "es" }},<span
        class="text-xsmallSpecial"
        >{{ ivaDec }}</span
      >
    </p>
    <p class="mt-1 text-xsmall">
      ${{ descuentos | currency : " " : "symbol" : "1.0-2" : "es" }},<span
        class="text-xsmallSpecial"
        >{{ descuentosDec }}</span
      >
    </p>
    <p class="text-black text-bold text-smallSpecial mt-1">
      ${{ total | currency : " " : "symbol" : "1.0-2" : "es" }},<span
        class="text-smallSpecial"
        >{{ totalDec }}</span
      >
    </p>
  </div>
</div>

<div (click)="finishPay()" class="buttonPay">
  <p class="text-title text-bold">Finalizar y Pagar</p>
</div>
