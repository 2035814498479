<!-- <nz-upload
  class="container-upload"
  nzType="drag"
  [nzMultiple]="false"
  [nzBeforeUpload]="beforeUploadProduct"
>  
  <div class="infoUpload">
    <p class="ant-upload-drag-icon">
      <i *ngIf="iconType === false" nz-icon nzType="camera" nzTheme="fill"></i>
      <i *ngIf="iconType === true" nz-icon nzType="file-add" nzTheme="fill"></i>
    </p>
    <p class="ant-upload-text">{{ data?.label }}</p>
    <p *ngIf="viewData === true" class="ant-upload-text">
      {{ data?.format }}
    </p>
    <p *ngIf="viewData === true" class="ant-upload-text">
      {{ data?.maxSizeMb }} MB
    </p>
  </div>
</nz-upload> -->

<!-- <div class="mt-2" *ngIf="listFile.length !== 0">
  <div *ngFor="let item of listFile; let i = index">
    <div class="item-file mt-1">
      <small class="text-special">{{ item.name }}</small>
      <i (click)="deletedFile(i)" class="far fa-trash"></i>
    </div>
  </div>
</div> -->

<div class="clearfix">
  <!-- nzListType="picture-card" -->
  <nz-upload
    class="container-upload"
    nzListType="picture-card"
    [nzShowUploadList]="nzShowUploadList"
    [nzCustomRequest]="customRequest"
    (nzChange)="onChange($event)"
    [(nzFileList)]="listFile"
    [nzShowButton]="listFile.length < 1"
    [nzPreview]="handlePreview"
  >
    <!-- [nzPreview]="handlePreview" -->
    <!-- [nzBeforeUpload]="beforeUploadProduct" -->
    <!-- <div>
      <span nz-icon nzType="plus"></span>
      <div style="margin-top: 8px">Upload</div>
    </div> -->
    <div class="infoUpload">
      <p class="ant-upload-drag-icon">
        <i
          *ngIf="iconType === false"
          nz-icon
          nzType="camera"
          nzTheme="fill"
        ></i>
        <i
          *ngIf="iconType === true"
          nz-icon
          nzType="file-add"
          nzTheme="fill"
        ></i>
      </p>
      <p class="ant-upload-text">{{ data?.label }}</p>
      <p *ngIf="viewData === true" class="ant-upload-text">
        {{ data?.format }}
      </p>
      <p *ngIf="viewData === true" class="ant-upload-text">
        {{ data?.maxSizeMb }} MB
      </p>
    </div>
  </nz-upload>
  <nz-modal
    [nzVisible]="previewVisible"
    [nzContent]="modalContent"
    [nzFooter]="null"
    (nzOnCancel)="previewVisible = false"
  >
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
  </nz-modal>
</div>
