import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/coguasimales/src/environments/environment';
import { Observable, Subject } from 'rxjs';

import { map } from 'rxjs/operators';
import { DetalleAgencia } from '../detalle-agencia/model/detalleAgencia';
import { GeneralInformation } from '../detalle-agencia/model/generalInformation';

@Injectable({
  providedIn: 'root',
})
export class AgenciaService {
  _baseURL: string = environment.base;
  // _baseURL: string = environment.baseDEV;

  idAgency: number;
  agencyActive: boolean;
  isAgencyBlock: boolean;
  isAgencyEdit: boolean;
  detalleAgencia: DetalleAgencia;
  infoGeneral: GeneralInformation;

  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getNotifications(): Observable<any> {
    const url = `${this._baseURL}/coguasimales/notifications`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  filterAgency(): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies-filterby`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  filterAgencyCode(code): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies-filterby/${code}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  searchAgency(params): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies-filterby/`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  detailsAgency(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  detailsAgencyActive(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/active`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  listOptionDeclined(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/declined-options?agencyId=${id}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  rejectPremium(body, id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/reject-request`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  downloadRequestPremium(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/doc-request`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  uploadRequestPremium(body, id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/doc-request`;

    var formData = new FormData();

    formData.append('files', body.files);
    formData.append('config_id', body.config_id);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  approveRequest(body, id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/approve-request`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  approveRequestStandar(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/approve-standard`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
          }),
        }
      )
      .pipe(map((data) => data));
  }

  getDataMenuLeft(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/left-panel`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  downloadFileAgency(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/files`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  blockAgency(id): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/block`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  listReasonCancelAgency(): Observable<any> {
    const url = `${this._baseURL}/coguasimales/close-options`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  cancelAgency(id, body): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/close`;

    var formData = new FormData();

    formData.append('closeOptionId', body.closeOptionId);
    for (let index = 0; index < body.closeFile.length; index++) {
      const element = body.closeFile[index].file;
      formData.append('closeFile', element);
    }

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  editAgency(id, body): Observable<any> {
    const url = `${this._baseURL}/coguasimales/agencies/${id}/edit`;

    var formData = new FormData();

    formData.append('fotoInterior', body.fotoInterior);
    formData.append('fotoFrontal', body.fotoFrontal);
    formData.append('fotoLateral', body.fotoLateral);
    formData.append('commercialName', body.commercialName);
    formData.append('nit', body.nit);
    formData.append('address', body.address);
    formData.append('phone', body.phone);
    formData.append('legalPersonPhone', body.legalPersonPhone);
    formData.append('email', body.email);
    formData.append('legalPersonName', body.legalPersonName);
    formData.append('legalPersonDocIdType', body.legalPersonDocIdType);
    formData.append('legalPersonDocId', body.legalPersonDocId);
    formData.append('legalPersonGender', body.legalPersonGender);
    formData.append('legalPersonBirthDate', body.legalPersonBirthDate);
    // formData.append('legalPersonMail', body.legalPersonMail);
    formData.append('latitude', body.latitude);
    formData.append('longitude', body.longitude);
    formData.append('stateId', body.stateId);
    formData.append('cityId', body.cityId);

    // formData.append('closeOptionId', body.closeOptionId);
    // for (let index = 0; index < body.closeFile.length; index++) {
    //   const element = body.closeFile[index].file;
    //   formData.append('closeFile', element);
    // }

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getSexo(): Observable<any> {
    const url = `${this._baseURL}/register/genders`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getListDepart(): Observable<any> {
    const url = `${this._baseURL}/register/states`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
  getTypeDocument(): Observable<any> {
    const url = `${this._baseURL}/register/identification-types`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }
  getListCity(params): Observable<any> {
    const url = `${this._baseURL}/register/cities`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }
}
