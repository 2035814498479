<div class="navbar">
  <div class="logo">
    <div class="content-img">
      <img src="/assets/img/Logo.svg" />
    </div>
    <p class="text-bold text-primary text-medium">PAGO FÁCIL V3</p>
  </div>

  <div *ngIf="deviceWidth > 1180" class="nav-list">
    <div *ngIf="apiRegister.isInDashboard" class="item">
      <p class="text-bold text-title">Coguasimales Services</p>
    </div>
    <div *ngIf="apiRegister.isInDashboard" class="item">
      <p class="text-bold text-title">Redsuelva</p>
    </div>
    <div *ngIf="apiRegister.isInDashboard" class="item">
      <p class="text-bold text-title">Contáctenos</p>
    </div>

    <div class="item">
      <button
        *ngIf="!nameUser"
        (click)="login()"
        class="btn btn-outline-primary"
      >
        Iniciar Sesión
      </button>
      <div class="text-black" *ngIf="nameUser">
        <a nz-dropdown [nzDropdownMenu]="menu">
          {{ nameUser }}
          <i nz-icon nzType="down"></i>
        </a>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="logout()">Cerrar Sesión</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>

  <div *ngIf="deviceWidth <= 1180" class="menu-mobile">
    <i
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menuMobile"
      class="fas fa-bars"
    ></i>

    <nz-dropdown-menu #menuMobile="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item>
          <span (click)="login()" class="text-primary text-bold text-title"
            >Iniciar Sesión</span
          >
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
