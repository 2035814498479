<div class="container-modal">
  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-bold text-small text-primary-light text-title text-center">
        Confirmación de bloqueo de agencia
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        ¿Está seguro que desea bloquear esta agencia? Al hacerlo no podrá
        iniciar sesión en la plataforma, con ninguna cuenta de usuario
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center" nzGutter="23">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal()">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        class="btn btn-secondary"
        (click)="confirBlockAgency(msjConfirmBlockAgency)"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>

<!-- MODALES -->

<ng-template #msjConfirmBlockAgency let-ref="modalRef">
  <div class="container-modal">
    <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p
          class="text-bold text-small text-primary-light text-title text-center"
        >
          Confirmación de bloqueo de agencia
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <p class="text-center">
          {{ msjSuccess }}
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <button class="btn btn-secondary" (click)="destroyModalRef(ref)">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</ng-template>
