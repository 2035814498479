export enum StatusAgency {
  NO_FINALIZADO = 'NO_FINALIZADO',
  PENDIENTE = 'PENDIENTE',
  APROBADO = 'APROBADO',
  NO_APROBADO = 'NO_APROBADO',
  DESACTIVADO = 'DESACTIVADO',
  NO_SOLICITADO = 'NO_SOLICITADO',
  SOLICITADO = 'SOLICITADO',
  PEND_APRO = 'PEND_APRO',
  ACT_ESTANDAR = 'ACT_ESTANDAR',
  ACT_PREMIUM = 'ACT_PREMIUM',
  BLOQ_TEMP = 'BLOQ_TEMP',
  BLOQ_INDEF = 'BLOQ_INDEF',
  PEND_ESTANDAR = 'PEND_ESTANDAR',
  PEND_PREMIUM = 'PEND_PREMIUM',
  CERRADA = 'CERRADA',
  PEND_TERMEST = 'PEND_TERMEST',
  PEND_TERMPRE = 'PEND_TERMPRE',
}
