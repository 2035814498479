<div class="error-container">
  <div class="container">
    <div class="logo">
      <img src="/assets/img/Logo.svg" />
    </div>

    <div class="text-center">
      <p class="text-title text-bold mt-4">
        Ha ocurrido un error, intenta mas tarde...
      </p>
      <button class="btn btn-contrast-color2 mt-2" (click)="toLogin()">
        Ir al login
      </button>
    </div>
  </div>

  <div class="mt-2">
    <div>
      <img src="/assets/img/logo2.svg" />
    </div>
  </div>
</div>
