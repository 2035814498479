import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/agencia/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetalleConsignacion } from '../model/detalle-consignacion';

@Injectable({
  providedIn: 'root',
})
export class AdministracionService {
  selectedConsig;
  detailsConsig: DetalleConsignacion;
  dateConsig;
  valueMaxConsig;
  reloadRow$ = new Subject();
  _baseURL: string = environment.base;
  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getListConsignaciones(params): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/consignments/`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
        params,
      })
      .pipe(map((data) => data));
  }

  getDetailsConsignacion(consignmentId): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/consignments/${consignmentId}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListBankAgency(): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/menu/agency-bank-list`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListBankAccounts(bankId): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/menu/bank/${bankId}/bank-accounts`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  newConsign(body): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/consignments/new`;

    var formData = new FormData();

    formData.append('bankId', body.bankId);
    formData.append('bankAccountId', body.bankAccountId);
    formData.append('consignmentDate', body.consignmentDate);
    formData.append('consignmentValue', body.consignmentValue);
    formData.append('consignmentNumber', body.consignmentNumber);
    formData.append('voucherImage', body.voucherImage);
    formData.append('consignmentObservation', body.consignmentObservation);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  editConsig(body, consignmentId): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/consignments/${consignmentId}/edit`;

    var formData = new FormData();

    formData.append('bankId', body.bankId);
    formData.append('bankAccountId', body.bankAccountId);
    formData.append('consignmentDate', body.consignmentDate);
    formData.append('consignmentValue', body.consignmentValue);
    formData.append('consignmentNumber', body.consignmentNumber);
    formData.append('voucherImage', body.voucherImage);
    formData.append('consignmentObservation', body.consignmentObservation);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: 'this.getToken()',
        }),
      })
      .pipe(map((data) => data));
  }

  cancelConsig(consignmentId): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/consignments/${consignmentId}/cancel`;
    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: 'this.getToken()',
          }),
        }
      )
      .pipe(map((data) => data));
  }
}
