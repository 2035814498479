import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'actions-agencia',
  templateUrl: './actions-agencia.component.html',
  styleUrls: ['./actions-agencia.component.scss'],
})
export class ActionsAgenciaComponent implements OnInit {
  @Output() eventEmitter = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  eventAction(action) {
    this.eventEmitter.emit(action);
  }
}
