import { LoginCoguasimalesService } from 'projects/coguasimales/src/app/auth/services/login-coguasimales.service';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalErrorComponent } from 'src/app/shared/Modals/modal-error/modal-error.component';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private modalService: NzModalService,
    public route: Router,
    public apiLoginCoguasimales: LoginCoguasimalesService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(req);

    const headers = this.getHeaders(req);

    req = req.clone({ headers });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.controlError(error);
      })
    );
  }

  getHeaders(req: HttpRequest<any>): HttpHeaders {
    const token = localStorage.getItem('token');
    let headers;

    if (token) {
      headers = new HttpHeaders({
        Accept: '*/*',
        Authorization: token,
      });
    } else {
      headers = new HttpHeaders({
        Accept: '*/*',
      });
    }

    return headers;
  }

  controlError(responseError: HttpErrorResponse) {
    let path = responseError.url.includes('coguasimales');
    if (responseError.error.errors) {
      let code = responseError.error.errors[0];
      switch (code.error) {
        case '01':
          this.apiLoginCoguasimales.errorSesion = true;
          this.apiLoginCoguasimales.msjErrorSesion =
            responseError.error.message;
          break;

        case '02':
          let data = {
            text: responseError.error.message,
            closeSesion: true,
          };
          this.createModalError(data);

          break;

        default:
          break;
      }
    } else {
      switch (responseError.status) {
        case 401:
          localStorage.removeItem('token');
          if (path) {
            this.route.navigate(['/loginCoguasimales']);
          } else {
            this.route.navigate(['/login']);
          }
          break;

        case 500:
          // if (path) {
          //   this.route.navigate(['/error/coguasimales']);
          // } else {
          //   this.route.navigate(['/error/agencia']);
          // }
          let dataError = {
            text: 'Lo sentimos ha ocurrido un error, intenta más tarde',
          };
          this.createModalError(dataError);
          break;

        default:
          let data = {
            text: responseError.error.message,
          };
          this.createModalError(data);
          break;
      }

      // if (responseError.status >= 500) {
      //   let dataError = {
      //     text: 'Lo sentimos ha ocurrido un error, intenta más tarde',
      //   };
      //   this.createModalError(dataError);
      // }
    }

    return throwError(responseError);
  }

  createModalError(dataError) {
    this.modalService.create({
      nzMaskClosable: false,
      nzCancelText: null,
      nzOkText: null,
      nzClosable: false,
      nzStyle: {},
      nzComponentParams: { dataError },
      nzWidth: 400,
      nzFooter: null,
      nzBodyStyle: {
        padding: '20px',
        color: '#707070',
      },
      nzContent: ModalErrorComponent,
    });
  }
}
