import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.scss'],
})
export class SearchClientComponent implements OnInit {
  @Input() listType;
  formSearchClient: FormGroup;
  constructor(public fb: FormBuilder) {
    this.formSearchClient = this.fb.group({
      identificationType: ['1'],
      search: [''],
    });
  }

  ngOnInit(): void {}

  searchClient() {
    console.log('buscar');
  }
}
