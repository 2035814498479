import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'info-purchase',
  templateUrl: './info-purchase.component.html',
  styleUrls: ['./info-purchase.component.scss'],
})
export class InfoPurchaseComponent implements OnInit {
  @Input() info;
  @Input() index;
  @Output() deleteProductEmitter = new EventEmitter<any>();
  valueProduct;
  valueDec;
  constructor() {}

  ngOnInit(): void {
    this.trasnformValue(this.info.total);
  }

  deleteProduct() {
    this.deleteProductEmitter.emit(this.index);
  }

  trasnformValue(value) {
    this.valueProduct = Math.trunc(value);

    if (value % 1 === 0) {
      this.valueDec = '00';
    } else {
      this.valueDec = value.toString().split('.')[1].substring(0, 2);
    }
  }
}
