<div nz-row nzAlign="middle">
  <div nz-col nzSpan="8">
    <p class="text-bold text-black text-title text-medium">A CELEBRAR!</p>
    <p class="text-primary-light text-bold text-title">
      Su solicitud ha sido aprobada!
    </p>
  </div>
  <div class="pad-left" nz-col nzSpan="16">
    <div>
      <div nz-row nzAlign="middle">
        <div class="pad-special" nz-col>
          <p class="text-bold text-black text-title">
            Para empezar a usar nuestra plataforma, debes aceptar las
            condiciones descritas en los siguientes enunciados
          </p>
        </div>
      </div>

      <div nz-row nzAlign="middle">
        <div class="pad-special" nz-col>
          <p>
            Completa el siguiente checklist de control de aprobación de registro
          </p>
        </div>
      </div>

      <div class="mt-2">
        <div class="indicadores">
          <div>
            <p class="text-bold text-black">SI</p>
          </div>
          <div>
            <p class="text-bold text-black">NO</p>
          </div>
        </div>
        <div *ngIf="loading">
          <form [formGroup]="formConditions">
            <div
              *ngFor="let item of listConditions; let i = index"
              class="container"
            >
              <div class="section-text">
                <p>Comprendo y Acepto los Términos y Condiciones</p>
              </div>
              <div>
                <nz-radio-group
                  class="section-radio"
                  [formControlName]="'radio-' + item.id"
                >
                  <label nz-radio nzValue="S"></label>
                  <label nz-radio nzValue="N"></label>
                </nz-radio-group>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="mt-2" nz-row nzAlign="middle" nzJustify="end">
      <div nz-col>
        <!-- [disabled]="!validForm" -->
        <button
          (click)="submit()"
          [disabled]="!validForm"
          class="btn btn-secondary"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
