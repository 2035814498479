<div class="tabsBar">
  <div
    class="tab"
    [ngClass]="tab.active ? 'active' : 'disabled'"
    *ngFor="let tab of arrayTabs"
    (click)="selectTab(tab)"
  >
    {{ tab.label }}
  </div>
</div>
