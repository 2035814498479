import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() arrayTabs;
  @Output() tabSelected = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  selectTab(tab: any) {
    this.tabSelected.emit(tab.label);

    this.arrayTabs.forEach((tab) => {
      tab.active = false;
    });
    tab.active = true;
  }
}
