<div class="container">
  <div nz-row nzAlign="middle" nzJustify="center">
    <div class="section-balance">
      <i class="fas fa-usd-circle"></i>
      <div class="description-balance">
        <p>$5.000.000</p>
        <!-- <p>
          ${{ data.availableCash | currency : " " : "symbol" : "1.0-2" : "es" }}
        </p> -->
        <small>Saldo Disponible</small>
      </div>
    </div>
  </div>

  <div class="mt-1" nz-row nzAlign="middle" nzJustify="center">
    <div class="section-commission">
      <div class="icon">
        <i class="fas fa-percent"></i>
      </div>
      <div class="description-balance">
        <p>$1.205.500</p>
        <!-- <p>
          ${{ data.totalComision | currency : " " : "symbol" : "1.0-2" : "es" }}
        </p> -->
        <small>Comisión Total</small>
      </div>
    </div>
  </div>
</div>
