import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { StatusAgency } from 'projects/coguasimales/src/app/agencia/model/statusAgency';
import { AgenciaService } from 'projects/coguasimales/src/app/agencia/services/agencia.service';

@Component({
  selector: 'app-modal-block-agency',
  templateUrl: './modal-block-agency.component.html',
  styleUrls: ['./modal-block-agency.component.scss'],
})
export class ModalBlockAgencyComponent implements OnInit {
  msjSuccess: string;

  @ViewChild('closeIcon') closeIcon: TemplateRef<any>;

  constructor(
    private modalService: NzModalService,
    private modal: NzModalRef,
    public apiAgencia: AgenciaService
  ) {}

  ngOnInit(): void {}

  closeRefModal() {
    this.destroyModal();
  }

  destroyModal() {
    this.modal.destroy();
  }

  destroyModalRef(id) {
    id.destroy();
  }

  confirBlockAgency(modal) {
    this.apiAgencia.blockAgency(this.apiAgencia.idAgency).subscribe(
      (resp) => {
        this.msjSuccess = resp.message;
        console.log(resp);

        this.apiAgencia.detailsAgency(this.apiAgencia.idAgency).subscribe(
          (resp) => {
            console.log(resp);
            this.apiAgencia.agencyActive = false;
            this.apiAgencia.isAgencyBlock = true;
            this.apiAgencia.detalleAgencia = resp.leftPanel;
            // this.infoAlertBlock.dateStatus = resp.leftPanel.status.dateStatus;
            // this.infoAlertBlock.userStatus = resp.leftPanel.status.userStatus;
            this.destroyModal();
            this.modalService.create({
              nzCancelText: null,
              nzOkText: null,
              nzFooter: null,
              nzStyle: {
                top: '25%',
              },
              nzCloseIcon: this.closeIcon,
              nzContent: modal,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
