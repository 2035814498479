<div>
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="space-between">
    <div nz-col></div>
    <div nz-col>
      <div class="title-modal">
        <p class="text-title text-bold text-primary-light text-small">
          Mi Perfil
        </p>
      </div>
    </div>
    <div nz-col class="edit">
      <i
        *ngIf="data.role[0].name === 'ROLE_SUPERVISOR'"
        (click)="editAcountProfile(editUser, closeIcon)"
        class="fas fa-edit"
      ></i>
    </div>
  </div>

  <div class="details-user mt-3">
    <div>
      <p>Nombres y Apellidos</p>
      <p class="mt-1">Corrreo Electrónico</p>
      <p class="mt-1">F. Nacimiento</p>
      <p class="mt-1">Sexo</p>
      <p class="mt-1">Rol de usuario</p>
    </div>
    <div>
      <p class="text-bold">{{ data.fullName }}</p>
      <p class="text-bold mt-1">{{ data.email }}</p>
      <p class="text-bold mt-1">{{ data.dateOfBirth }}</p>
      <p class="text-bold mt-1">{{ data.sex.genderName }}</p>
      <p class="text-bold mt-1">{{ data.role[0].description }}</p>
    </div>
  </div>

  <div class="mt-1">
    <div class="w-50">
      <p class="link" (click)="resetPassword(recoverPassword, closeIcon)">
        Reestablecer contraseña
      </p>
    </div>
    <div></div>
  </div>

  <!-- <div class="mt-3" nz-row nzAlign="middle" nzGutter="30" nzJustify="center">
      <div nz-col class="gutter-row" nzSpan="12">
        <p>Nombres y Apellidos</p>
        <p class="mt-1">Corrreo Electrónico</p>
        <p class="mt-1">F. Nacimiento</p>
        <p class="mt-1">Sexo</p>
        <p class="mt-1">Rol de usuario</p>
      </div>
      <div nz-col class="gutter-row" nzSpan="12">
        <p class="text-bold">{{ data.fullName }}</p>
        <p class="text-bold mt-1">{{ data.email }}</p>
        <p class="text-bold mt-1">{{ data.dateOfBirth }}</p>
        <p class="text-bold mt-1">{{ data.sex }}</p>
        <p class="text-bold mt-1">{{ data.role[0].description }}</p>
      </div>
    </div> -->

  <!-- <div class="mt-1" nz-row nzAlign="middle" nzGutter="30" nzJustify="center">
      <div nz-col class="gutter-row" nzSpan="14">      
        <p class="link" (click)="resetPassword(recoverPassword, closeIcon)">
          Reestablecer contraseña
        </p>
      </div>
      <div nz-col class="gutter-row" nzSpan="6"></div>
    </div> -->
</div>

<ng-template #recoverPassword let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div class="title-modal">
        <p class="text-title text-bold text-primary-light text-small">
          Detalle de Cuenta de Usuario
        </p>
      </div>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzGutter="23">
    <div nz-col class="gutter-row">
      <label>Corrreo Electrónico</label>
    </div>

    <div nz-col class="gutter-row">
      <label class="text-bold">{{ data.email }}</label>
    </div>
  </div>

  <form [formGroup]="formRecoveryPassword">
    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col nzSpan="16">
        <label>Nueva contraseña <span class="text-danger">*</span></label>
        <input
          nz-input
          nzSize="large"
          formControlName="newPassword"
          type="password"
          placeholder=""
          (ngModelChange)="confirmPassword($event)"
        />
        <div
          class="absolute"
          *ngIf="fRecovery.newPassword.invalid && fRecovery.newPassword.touched"
        >
          <small
            *ngIf="fRecovery.newPassword.errors?.required"
            class="text-danger"
            >Este campo es obligatorio</small
          >
          <small
            *ngIf="fRecovery.newPassword.errors?.pattern"
            class="text-danger"
            >Contraseña inválida</small
          >
        </div>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col nzSpan="16">
        <label>Repetir contraseña <span class="text-danger">*</span></label>
        <input
          nz-input
          nzSize="large"
          formControlName="confirPassword"
          type="password"
          placeholder=""
          (ngModelChange)="confirmPassword($event)"
        />
        <div
          class="absolute"
          *ngIf="
            (fRecovery.confirPassword.invalid || !equalsPassword) &&
            fRecovery.confirPassword.touched
          "
        >
          <small *ngIf="!equalsPassword" class="text-danger">
            Las contraseñas deben coincidir
          </small>
        </div>
      </div>
    </div>
  </form>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button (click)="closeModalRef(ref)" class="btn btn-outline-danger">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        [disabled]="!formRecoveryPassword.valid || !equalsPassword"
        class="btn btn-secondary"
        (click)="openModalRef(confirChange, ref, closeIcon)"
      >
        Actualizar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirChange let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de cambio de contraseña
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        La contraseña ha sido actualizada correctamente, se cerrarán todas las
        sesiones activas de este usuario en caso que existiera alguna
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button class="btn btn-secondary" (click)="closeModalRef(ref)">
        Aceptar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editUser let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <div>
        <p class="text-title text-bold text-primary-light text-small">
          Editar Perfil
        </p>
      </div>
    </div>
  </div>

  <div class="mt-2" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col nzSpan="16">
      <p class="text-center">Edite la información requerida para tu perfil</p>
    </div>
  </div>

  <form [formGroup]="formEditUser" autocomplete="off">
    <div nz-row nzAlign="middle">
      <div nz-col nzSpan="24">
        <label>Nombres y Apellidos <span class="text-danger">*</span></label>
        <input
          nz-input
          formControlName="name"
          nzSize="large"
          placeholder=""
          (input)="validName($event)"
        />
        <div
          class="absolute"
          *ngIf="fEditUser.name.invalid && fEditUser.name.touched"
        >
          <small *ngIf="fEditUser.name.errors?.required" class="text-danger"
            >Este campo es obligatorio</small
          >
        </div>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col nzSpan="24">
        <label>Correo Electrónico <span class="text-danger">*</span></label>
        <input nz-input formControlName="email" nzSize="large" placeholder="" />
        <div
          class="absolute"
          *ngIf="fEditUser.email.invalid && fEditUser.email.touched"
        >
          <small *ngIf="fEditUser.email.errors?.required" class="text-danger"
            >Este campo es obligatorio</small
          >
          <small *ngIf="fEditUser.email.errors?.pattern" class="text-danger"
            >Formato de correo inválido (ejemplo@mail.com)</small
          >
        </div>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzGutter="23">
      <div nz-col nzSpan="12" class="gutter-row">
        <label>Sexo <span class="text-danger">*</span></label>
        <nz-select
          class="mt-1"
          formControlName="sexo"
          nzSize="large"
          nzPlaceHolder="Seleccione el sexo"
        >
          <nz-option
            *ngFor="let item of listSexo"
            [nzLabel]="item.genderName"
            [nzValue]="item.id"
          >
          </nz-option>
        </nz-select>
        <div
          class="absolute"
          *ngIf="fEditUser.sexo.invalid && fEditUser.sexo.touched"
        >
          <small *ngIf="fEditUser.sexo.errors?.required" class="text-danger"
            >Este campo es obligatorio</small
          >
        </div>
      </div>

      <div nz-col nzSpan="12" class="gutter-row">
        <label>Fecha de Nacimiento <span class="text-danger">*</span></label>
        <div class="mt-1">
          <nz-date-picker
            [nzInputReadOnly]="true"
            formControlName="fecha"
            [nzFormat]="'dd/MM/yyyy'"
            class="w-100"
            nzSize="large"
            (ngModelChange)="getDate($event)"
            (nzOnOpenChange)="detectedDateEdit($event)"
          ></nz-date-picker>
        </div>
        <div class="absolute">
          <small *ngIf="edad < 18" class="text-danger"
            >Debe ser mayor de edad</small
          >
        </div>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle" nzGutter="23">
      <div nz-col nzSpan="12" class="gutter-row">
        <label>Rol de usuario <span class="text-danger">*</span></label>
        <nz-select
          class="mt-1"
          formControlName="rol"
          nzSize="large"
          nzPlaceHolder="Seleccione el rol"
        >
          <nz-option
            *ngFor="let item of listRoles"
            [nzLabel]="item.description"
            [nzValue]="item.roleId"
          >
          </nz-option>
        </nz-select>
        <div
          class="absolute"
          *ngIf="fEditUser.rol.invalid && fEditUser.rol.touched"
        >
          <small *ngIf="fEditUser.rol.errors?.required" class="text-danger"
            >Este campo es obligatorio</small
          >
        </div>
      </div>

      <div nz-col nzSpan="12" class="gutter-row mt-1">
        <button
          [disabled]="!formEditUser.valid || !validDate"
          (click)="confirEdit(ref, msjModal, closeIcon)"
          class="btn btn-secondary mt-2"
        >
          Actualizar
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #msjModal let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">
        {{ msjSuccess }}
      </p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeModalRef(ref)" class="btn btn-secondary">Ok</button>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</ng-template>
