<nz-table
  #virtualTable
  nzTableLayout="fixed"
  [nzData]="listOfData"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  [nzVirtualForTrackBy]="trackByIndex"
  [nzVirtualItemSize]="54"
  [nzScroll]="{ x: '0px', y: '240px' }"
  [nzWidthConfig]="widthConfig"
  [nzNoResult]="empty"
>
  <thead>
    <tr>
      <th *ngIf="isCheckbox"></th>
      <th nzAlign="center" *ngFor="let header of tableHeaders">
        <!-- class="text-ellipsis" -->
        <p [title]="header.label">{{ header.label }}</p>
      </th>
      <th nzAlign="left">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <ng-template nz-virtual-scroll let-data let-index="index">
      <tr
        [ngClass]="{ styleTable: index % 2 === 0 }"
        (click)="selectedRow(data)"
      >
        <td
          *ngIf="isCheckbox"
          [nzChecked]="setOfCheckedId.has(data.id)"
          (nzCheckedChange)="onItemChecked(data.id, $event, data)"
        ></td>
        <td nzEllipsis nzAlign="center">
          {{ data.noDocumento }}
        </td>
        <td nzEllipsis nzAlign="center">
          {{ data.proveedor }}
        </td>
        <td nzEllipsis nzAlign="center">
          {{ data.convenio }}
        </td>
        <td nzEllipsis nzAlign="center">
          {{ data.noFactura }}
        </td>
        <td nzEllipsis nzAlign="center">
          {{ data.noReferencia }}
        </td>
        <td nzEllipsis nzAlign="center">
          <div>${{ data.pagoMinimo }}</div>
        </td>
        <td nzAlign="center">
          <div *ngIf="!enableField">${{ data.valorFactura }}</div>
          <input
            *ngIf="enableField"
            [ngClass]="{
              error: getValidField(index, data.valorFactura, data.pagoMinimo)
            }"
            type="text"
            nz-input
            [(ngModel)]="data.valorFactura"
            mask="separator ,10"
            thousandSeparator="."
            prefix="$ "
          />
        </td>
        <td nzAlign="center">
          <div *ngIf="isObject(data.actions)">
            <div
              *ngIf="data.actions.icono"
              class="icono"
              [ngClass]="{ iconDisable: data.actions.disabled }"
              (click)="actionEmit(data)"
              [ngStyle]="{ color: data.actions.color }"
            >
              <i [class]="data.actions.icono"></i>
            </div>

            <div *ngIf="!data.actions.icono">
              <div
                class="estado"
                [ngStyle]="{
                  color: data.actions.color,
                  'background-color': data.actions.background
                }"
              >
                <p>{{ data.actions.nombre }}</p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>

<ng-template #empty>
  <div class="mt-2">
    <nz-empty
      nzNotFoundImage="/assets/img/noResult.svg"
      [nzNotFoundContent]="null"
    ></nz-empty>
    <div class="text-empty" nz-row nzAlign="top" nzJustify="center">
      <div nz-col nzSpan="16">
        <p>
          No se encontraron resultados de la búsqueda realizada Intenta
          nuevamente
        </p>
      </div>
    </div>
  </div>
</ng-template>
