<div class="container">
  <!-- BANCOS -->
  <div *ngIf="value === 'addBank'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Registrar Banco
        </p>
      </div>
    </div>

    <div class="mt-5">
      <form [formGroup]="formAddBank">
        <div nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <label>Nombre del banco *</label>
            <input
              (input)="validFiled($event, 'name')"
              maxlength="25"
              formControlName="name"
              nz-input
              nzSize="large"
              placeholder="Ingresar nombre"
            />
            <div
              class="absolute"
              *ngIf="fAddBank.name.invalid && fAddBank.name.touched"
            >
              <small *ngIf="fAddBank.name.errors?.required" class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <label>NIT *</label>

            <input
              nz-input
              onkeypress="return (event.keyCode == 45 || ((event.keyCode >= 97) && (event.keyCode <= 122)) || ( (event.keyCode >= 48) && (event.keyCode <=57)) || ( (event.keyCode >= 65) && (event.keyCode <=90)) )"
              (input)="validFiled($event, 'nit')"
              formControlName="nit"
              maxlength="20"
              nzSize="large"
              placeholder="Ingresar NIT"
            />
            <div
              class="absolute"
              *ngIf="fAddBank.nit.invalid && fAddBank.nit.touched"
            >
              <small *ngIf="fAddBank.nit.errors?.required" class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24">
            <label>Estado *</label>
            <nz-select
              formControlName="estado"
              nzSize="large"
              nzPlaceHolder="Seleccionar estado"
            >
              <nz-option nzValue="0" nzLabel="Activo"></nz-option>
              <nz-option nzValue="1" nzLabel="Inactivo"></nz-option>
              <!-- <nz-option
                *ngFor="let item of listBankStatus"
                [nzLabel]="item.listBankStatus"
                [nzValue]="item.id"
                >
              </nz-option> -->
            </nz-select>
            <div
              class="absolute"
              *ngIf="fAddBank.estado.invalid && fAddBank.estado.touched"
            >
              <small
                *ngIf="fAddBank.estado.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-textarea">
            <label>Observación</label>
            <textarea
              nz-input
              formControlName="observacion"
              maxlength="100"
              [nzAutosize]="{ minRows: 3, maxRows: 3 }"
              placeholder="Ingresar observación"
            ></textarea>
          </div>
        </div>
      </form>

      <div class="mt-5" nz-row nzAlign="middle" nzJustify="space-between">
        <div nz-col>
          <p (click)="closeDrawer()" class="close-drawer">Cancelar</p>
        </div>
        <div nz-col>
          <button
            [disabled]="!formAddBank.valid"
            class="btn btn-primary"
            (click)="createModal(createBank)"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="value === 'viewBank'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Datos del Banco
        </p>
      </div>
    </div>

    <div class="mt-5" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fal fa-university"></i>
          <div class="description-details">
            <p class="text-bold text-black">Nombre del Banco</p>
            <small>Banco</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="far fa-money-check"></i>
          <div class="description-details">
            <p class="text-bold text-black">00000000000</p>
            <small>NIT</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="far fa-check-circle"></i>
          <div class="description-details">
            <p class="text-bold text-black">Activo</p>
            <small>Estado</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-calendar-alt"></i>
          <div class="description-details">
            <p class="text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
            <small>Fecha de registro</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-calendar-alt"></i>
          <div class="description-details">
            <p class="text-bold text-black">DD/MM/AAAA</p>
            <small>Fecha de modificación</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fal fa-user"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              Ana María Teresa del Toro Palacios y Ponte
            </p>
            <small>Modificada por</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada del banco que se registra, observaciones
              requeridas a tal punto de especificación
            </p>
            <small>Observaciones</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
      <div nz-col>
        <div (click)="editIsBank()" class="icon-edit">
          <i class="fal fa-pen"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="value === 'editBank'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Actualizar Banco
        </p>
      </div>
    </div>

    <div class="mt-5">
      <form [formGroup]="formEditBank">
        <div nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <label>Nombre del banco *</label>
            <input
              maxlength="25"
              (input)="validFiled($event, 'name')"
              formControlName="name"
              nz-input
              nzSize="large"
              placeholder="Ingresar nombre"
            />
            <div
              class="absolute"
              *ngIf="fEditBank.name.invalid && fEditBank.name.touched"
            >
              <small *ngIf="fEditBank.name.errors?.required" class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <label>NIT *</label>
            <input
              nz-input
              onkeypress="return (event.keyCode == 45 || ((event.keyCode >= 97) && (event.keyCode <= 122)) || ( (event.keyCode >= 48) && (event.keyCode <=57)) || ( (event.keyCode >= 65) && (event.keyCode <=90)) )"
              formControlName="nit"
              (input)="validFiled($event, 'nit')"
              maxlength="20"
              nzSize="large"
              placeholder="Ingresar NIT"
            />
            <div
              class="absolute"
              *ngIf="fEditBank.nit.invalid && fEditBank.nit.touched"
            >
              <small *ngIf="fEditBank.nit.errors?.required" class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24">
            <label>Estado *</label>
            <nz-select
              formControlName="estado"
              nzSize="large"
              nzPlaceHolder="Seleccione estado"
            >
              <nz-option [nzValue]="0" nzLabel="Activo"></nz-option>
              <nz-option [nzValue]="1" nzLabel="Inactivo"></nz-option>
              <!-- <nz-option
                *ngFor="let item of listBankStatus"
                [nzLabel]="item.listBankStatus"
                [nzValue]="item.id"
                >
              </nz-option> -->
            </nz-select>
            <div
              class="absolute"
              *ngIf="fEditBank.estado.invalid && fEditBank.estado.touched"
            >
              <small
                *ngIf="fEditBank.estado.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-textarea">
            <label>Observación</label>
            <textarea
              formControlName="observacion"
              nz-input
              maxlength="100"
              [nzAutosize]="{ minRows: 3, maxRows: 3 }"
              placeholder="Ingresar observación"
            ></textarea>
          </div>
        </div>
      </form>

      <div class="mt-5" nz-row nzAlign="middle" nzJustify="space-between">
        <div nz-col>
          <p (click)="closeDrawer()" class="close-drawer">Cancelar</p>
        </div>
        <div nz-col>
          <button
            [disabled]="!formEditBank.valid"
            class="btn btn-primary"
            (click)="createModal(editBank)"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--  -->

  <!-- CONSIGNACIONES -->

  <div *ngIf="value === 'addConsig'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Realizar Consignación
        </p>
      </div>
    </div>

    <div class="mt-5">
      <form [formGroup]="formAddConsig" autocomplete="off">
        <div nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <p>Banco *</p>
            <div class="selectClass">
              <select
                formControlName="bank"
                (change)="detectedBank($event.target.value)"
                required
              >
                <option disabled selected hidden value="">
                  Seleccionar banco
                </option>
                <option
                  *ngFor="let item of agencyBankList; let i = index"
                  [value]="item.bankId"
                >
                  {{ item.bankName }}
                </option>
              </select>
              <i class="fal fa-chevron-down"></i>
            </div>
            <div
              class="absolute"
              *ngIf="fAddConsig.bank.invalid && fAddConsig.bank.touched"
            >
              <small
                *ngIf="fAddConsig.bank.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" *ngIf="!bankSelected">
          <p class="text-bold text-black">¡Por favor!</p>
          <p class="mt-2">
            Selecciona el banco al que realizaste la consignación y completa la
            siguiente información que se visualizara a continuación.
          </p>
        </div>

        <div *ngIf="bankSelected">
          <div class="mt-4">
            <div class="content-imgBank">
              <img [src]="imgBank" alt="" />
            </div>
          </div>

          <div class="mt-4" nz-row nzAlign="top">
            <div nz-col nzSpan="24" class="content-input">
              <p>Número de cuenta *</p>

              <div class="selectClass">
                <select formControlName="noCuenta" required>
                  <option disabled selected hidden value="">
                    Seleccionar número de cuenta
                  </option>
                  <option
                    *ngFor="let item of listBankAccounts"
                    [value]="item.accountBankId"
                  >
                    {{ item.accountBankNumber }}
                  </option>
                </select>
                <i class="fal fa-chevron-down"></i>
              </div>

              <!-- <nz-select
                formControlName="noCuenta"
                nzSize="large"
                nzPlaceHolder="Seleccionar número de cuenta"
              >
                <nz-option
                  *ngFor="let item of listBankAccounts"
                  [nzLabel]="item.accountBankNumber"
                  [nzValue]="item.accountBankId"
                >
                </nz-option>
              </nz-select> -->

              <div
                class="absolute"
                *ngIf="
                  fAddConsig.noCuenta.invalid && fAddConsig.noCuenta.touched
                "
              >
                <small
                  *ngIf="fAddConsig.noCuenta.errors?.required"
                  class="text-danger"
                  >Este campo es obligatorio</small
                >
              </div>
            </div>
          </div>

          <div class="mt-4" nz-row nzAlign="top">
            <div nz-col nzSpan="24" class="content-input">
              <label>Fecha de consignación *</label>

              <div>
                <nz-date-picker
                  [nzInputReadOnly]="true"
                  formControlName="fechaConsig"
                  [nzDisabledDate]="disabledDate"
                  [nzFormat]="'dd/MM/yyyy'"
                  class="w-100"
                  nzSize="large"
                  (ngModelChange)="getDate($event)"
                  (nzOnOpenChange)="detectedDate($event)"
                ></nz-date-picker>
              </div>

              <div
                class="absolute"
                *ngIf="
                  fAddConsig.fechaConsig.invalid &&
                  fAddConsig.fechaConsig.touched
                "
              >
                <small
                  *ngIf="fAddConsig.fechaConsig.errors?.required"
                  class="text-danger"
                  >Este campo es obligatorio</small
                >
              </div>
            </div>
          </div>

          <div class="mt-4" nz-row nzAlign="top">
            <div nz-col nzSpan="24">
              <label>Número de consignación *</label>
              <input
                nz-input
                nzSize="large"
                (input)="validFiled($event, 'noConsig')"
                formControlName="noConsig"
                placeholder="Ingrese número de consignación"
              />
              <div
                class="absolute"
                *ngIf="
                  fAddConsig.noConsig.invalid && fAddConsig.noConsig.touched
                "
              >
                <small
                  *ngIf="fAddConsig.noConsig.errors?.required"
                  class="text-danger"
                  >Este campo es obligatorio</small
                >
              </div>
            </div>
          </div>

          <div class="mt-4" nz-row nzAlign="top">
            <div nz-col nzSpan="24">
              <label>Valor de la consignación *</label>
              <input
                nz-input
                nzSize="large"
                mask="separator ,10"
                thousandSeparator="."
                (blur)="cleanMsj()"
                (input)="validValueConsig($event.target.value)"
                [prefix]="fAddConsig.valueConsig.value ? '$' : ''"
                formControlName="valueConsig"
                placeholder="Ingresar valor"
              />
              <div class="absolute">
                <small *ngIf="msjErrorValue" class="text-danger">
                  {{ msjErrorValue }} ${{
                    valueMax | currency : " " : "symbol" : "1.0-2" : "es"
                  }}
                </small>
                <small *ngIf="msjAviso" class="text-primary">
                  {{ msjAviso }} ${{
                    valueMax | currency : " " : "symbol" : "1.0-2" : "es"
                  }}
                </small>
              </div>
              <div
                class="absolute"
                *ngIf="
                  fAddConsig.valueConsig.invalid &&
                  fAddConsig.valueConsig.touched
                "
              >
                <small
                  *ngIf="fAddConsig.valueConsig.errors?.required"
                  class="text-danger"
                  >Este campo es obligatorio</small
                >
              </div>
            </div>
          </div>

          <div class="mt-4" nz-row nzAlign="top">
            <div nz-col nzSpan="24">
              <label>Adjuntar comprobante de la consignación *</label>
              <file-uploader
                (fileEmitter)="getFile($event)"
                [fileExist]="fileExist"
                [title]="'Adjuntar comprobante'"
                [icon]="icon"
              >
              </file-uploader>
              <div class="absolute" *ngIf="msjErrorFile">
                <small [ngClass]="errorFile ? 'text-danger' : 'text-primary'">{{
                  msjErrorFile
                }}</small>
              </div>
            </div>
          </div>

          <div class="mt-3" nz-row nzAlign="top">
            <div nz-col nzSpan="24" class="content-textarea">
              <label>Observación</label>
              <textarea
                nz-input
                formControlName="observacion"
                maxlength="100"
                [nzAutosize]="{ minRows: 3, maxRows: 3 }"
                placeholder="Ingresar observación"
              ></textarea>
            </div>
          </div>
        </div>
      </form>

      <div class="mt-5" nz-row nzAlign="middle" nzJustify="space-between">
        <div nz-col>
          <p (click)="closeDrawer()" class="close-drawer">Cancelar</p>
        </div>
        <div nz-col>
          <button
            [disabled]="
              !formAddConsig.valid ||
              !fileExist ||
              !consigValid ||
              msjErrorValue
            "
            class="btn btn-primary"
            (click)="createModal(createConsig)"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="value === 'viewConsig'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Detalle de la Consignación
        </p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col>
        <div
          class="estadoConsig"
          [ngStyle]="{
            background: apiAdminAgencia.detailsConsig.consignmentStatus.color
          }"
        >
          <p class="text-bold">
            {{ apiAdminAgencia.detailsConsig.consignmentStatus.description }}
          </p>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        apiAdminAgencia.detailsConsig.consignmentStatus.description ===
        'RECHAZADA'
      "
      class="mt-4"
      nz-row
      nzAlign="middle"
    >
      <div nz-col>
        <div>
          <p class="text-bold text-title text-primary">Motivo de Rechazo</p>
        </div>
        <div class="content-details mt-3">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada del motivo por el cual rechazaron la
              consignación que se realizo
            </p>
            <small>Motivo de rechazo</small>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        apiAdminAgencia.detailsConsig.consignmentStatus.description ===
        'DEVUELTA'
      "
      class="mt-4"
      nz-row
      nzAlign="middle"
    >
      <div nz-col>
        <div>
          <p class="text-bold text-title text-primary">Motivo de devolución</p>
        </div>
        <div
          class="mt-3"
          *ngFor="
            let reason of apiAdminAgencia.detailsConsig.consignmentReasonStatus
          "
        >
          <div class="rejected-details">
            <i class="far fa-times-circle"></i>
            <div class="description-reject">
              <p class="text-bold text-black">{{ reason.consignmentField }}</p>
              <small>{{ reason.consignmentField }}</small>
            </div>
          </div>
        </div>

        <!-- <div class="mt-2">
          <div class="rejected-details">
            <i class="far fa-times-circle"></i>
            <div class="description-reject">
              <p class="text-bold text-black">Valor de la consignación</p>
              <small>Problemas en el valor de la consignación</small>
            </div>
          </div>
        </div> -->

        <div class="content-details mt-2">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada del motivo de devolución de la consginación
              que se realizo
            </p>
            <small>Motivo de devolución</small>
          </div>
        </div>

        <!-- <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
          <div nz-col>
            <div (click)="editIsConsig()" class="icon-edit">
              <i class="fal fa-pen"></i>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="mt-3 position-relative">
      <div class="content-imgVoucherActions">
        <img
          [src]="apiAdminAgencia.detailsConsig.voucherImage.voucherFileLink"
          alt=""
        />

        <div class="description">
          <p>Comprobante de consignación</p>
          <div class="imgVoucherActions">
            <i (click)="viewImgConsign()" class="fas fa-search-plus"></i>
            <i
              (click)="
                downloadConsig(
                  apiAdminAgencia.detailsConsig.voucherImage.voucherFileLink
                )
              "
              class="fas fa-arrow-down"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fal fa-university"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.detailsConsig.bankName }}
            </p>
            <small>Banco</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-hashtag"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.detailsConsig.bankAccountNumber }}
            </p>
            <small>Número de cuenta</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-calendar-alt"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.dateConsig }}
            </p>
            <small>Fecha</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-hashtag"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.detailsConsig.consignmentNumber }}
            </p>
            <small>Número de consignación</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-dollar-sign"></i>
          <div class="description-details">
            <p class="text-bold text-black">
              {{
                apiAdminAgencia.detailsConsig.consignmentValue
                  | currency : " " : "symbol" : "1.0-2" : "es"
              }}
            </p>
            <small>Valor de la consignación</small>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.detailsConsig.voucherImage.voucherFileName }}
            </p>
            <div class="contentVaucher">
              <small>Comprobante de consignación</small>
              <img
                (click)="
                  downloadConsig(
                    apiAdminAgencia.detailsConsig.voucherImage.voucherFileLink
                  )
                "
                [src]="'./assets/img/download.svg'"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <div class="description-details">
            <p class="text-bold text-black">
              {{ apiAdminAgencia.detailsConsig.consignmentObservation }}
            </p>
            <small>Observaciones</small>
          </div>
        </div>
      </div>
    </div>

    <!-- TERMINAR DE CONECTAR VIEWCONSIG -->

    <div class="mt-5" nz-row nzAlign="middle" nzJustify="center" nzGutter="40">
      <div
        *ngIf="
          apiAdminAgencia.detailsConsig.consignmentStatus.description ===
          'DEVUELTA'
        "
        nz-col
      >
        <div (click)="editIsConsig()" class="icon-edit">
          <span class="tiptext">Editar</span>
          <i class="fal fa-pen"></i>
        </div>
      </div>
      <div nz-col>
        <div (click)="viewRecord()" class="icon-edit">
          <span class="tiptext">Historial de movimientos</span>
          <img src="./assets/img/record.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- FIN -->
  </div>

  <div *ngIf="value === 'editConsig'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Actualizar Consignación
        </p>
      </div>
    </div>

    <div class="mt-5">
      <form [formGroup]="formEditConsig" autocomplete="off">
        <div nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <!-- <label>Banco *</label> -->
            <!-- <nz-select
              formControlName="bank"
              nzSize="large"
              nzPlaceHolder="Seleccionar banco"
              (ngModelChange)="detectedBank($event)"
            >
              <nz-option
                *ngFor="let item of agencyBankList"
                [nzLabel]="item.bankName"
                [nzValue]="item.bankId"
              >
              </nz-option>
            </nz-select> -->

            <p>Banco *</p>
            <div class="selectClass">
              <select
                formControlName="bank"
                (change)="detectedBank($event.target.value)"
                required
              >
                <option disabled selected hidden value="">
                  Seleccionar banco
                </option>
                <option
                  *ngFor="let item of agencyBankList; let i = index"
                  [value]="item.bankId"
                >
                  {{ item.bankName }}
                </option>
              </select>
              <i class="fal fa-chevron-down"></i>
            </div>

            <div
              class="absolute"
              *ngIf="fEditConsig.bank.invalid && fEditConsig.bank.touched"
            >
              <small
                *ngIf="fEditConsig.bank.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="content-imgBank">
            <img [src]="imgBank" alt="" />
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <p>Número de cuenta *</p>

            <div class="selectClass">
              <select formControlName="noCuenta" required>
                <option disabled selected hidden value="">
                  Seleccionar número de cuenta
                </option>
                <option
                  *ngFor="let item of listBankAccounts"
                  [value]="item.accountBankId"
                >
                  {{ item.accountBankNumber }}
                </option>
              </select>
              <i class="fal fa-chevron-down"></i>
            </div>
            <!-- <label>Número de cuenta *</label>

            <nz-select
              formControlName="noCuenta"
              nzSize="large"
              nzPlaceHolder="Seleccionar número de cuenta"
            >
              <nz-option
                *ngFor="let item of listBankAccounts"
                [nzLabel]="item.accountBankNumber"
                [nzValue]="item.accountBankId"
              >
              </nz-option>
            </nz-select> -->
            <div
              class="absolute"
              *ngIf="
                fEditConsig.noCuenta.invalid && fEditConsig.noCuenta.touched
              "
            >
              <small
                *ngIf="fEditConsig.noCuenta.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-input">
            <label>Fecha de consignación *</label>

            <div>
              <nz-date-picker
                [nzInputReadOnly]="true"
                formControlName="fechaConsig"
                [nzDisabledDate]="disabledDate"
                [nzFormat]="'dd/MM/yyyy'"
                class="w-100"
                nzSize="large"
                (ngModelChange)="getDateEditConsig($event)"
                (nzOnOpenChange)="detectedDate($event)"
              ></nz-date-picker>
            </div>

            <div
              class="absolute"
              *ngIf="
                fEditConsig.fechaConsig.invalid &&
                fEditConsig.fechaConsig.touched
              "
            >
              <small
                *ngIf="fEditConsig.fechaConsig.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24">
            <label>Número de consignación *</label>
            <input
              nz-input
              nzSize="large"
              formControlName="noConsig"
              (input)="validFiled($event, 'noConsig')"
              placeholder="Ingrese número de consignación"
            />
            <div
              class="absolute"
              *ngIf="
                fEditConsig.noConsig.invalid && fEditConsig.noConsig.touched
              "
            >
              <small
                *ngIf="fEditConsig.noConsig.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24">
            <label>Valor de la consignación *</label>
            <input
              nz-input
              nzSize="large"
              mask="separator ,10"
              thousandSeparator="."
              (input)="validValueConsig($event.target.value)"
              [prefix]="fEditConsig.valueConsig.value ? '$' : ''"
              formControlName="valueConsig"
              placeholder="Ingrese valor"
            />
            <div class="absolute">
              <small *ngIf="msjErrorValue" class="text-danger">
                {{ msjErrorValue }} ${{
                  valueMax | currency : " " : "symbol" : "1.0-2" : "es"
                }}
              </small>
              <small *ngIf="msjAviso" class="text-primary">
                {{ msjAviso }} ${{
                  valueMax | currency : " " : "symbol" : "1.0-2" : "es"
                }}
              </small>
            </div>
            <div
              class="absolute"
              *ngIf="
                fEditConsig.valueConsig.invalid &&
                fEditConsig.valueConsig.touched
              "
            >
              <small
                *ngIf="fEditConsig.valueConsig.errors?.required"
                class="text-danger"
                >Este campo es obligatorio</small
              >
            </div>
          </div>
        </div>

        <div class="mt-4" nz-row nzAlign="top">
          <div nz-col nzSpan="24">
            <label>Adjuntar comprobante de la consignación *</label>
            <file-uploader
              (fileEmitter)="getFile($event)"
              [fileExist]="fileExist"
              [title]="'Adjuntar comprobante'"
              [icon]="icon"
            >
            </file-uploader>
            <div class="absolute" *ngIf="msjErrorFile">
              <small [ngClass]="errorFile ? 'text-danger' : 'text-primary'">{{
                msjErrorFile
              }}</small>
            </div>
          </div>
        </div>

        <div class="mt-3" nz-row nzAlign="top">
          <div nz-col nzSpan="24" class="content-textarea">
            <label>Observación</label>
            <textarea
              nz-input
              formControlName="observacion"
              maxlength="100"
              [nzAutosize]="{ minRows: 3, maxRows: 3 }"
              placeholder="Ingresar observación"
            ></textarea>
          </div>
        </div>
      </form>

      <div class="mt-5" nz-row nzAlign="middle" nzJustify="space-between">
        <div nz-col>
          <p (click)="closeDrawer()" class="close-drawer">Cancelar</p>
        </div>
        <div nz-col>
          <button
            [disabled]="
              !formEditConsig.valid ||
              !fileExist ||
              !consigValid ||
              msjErrorValue
            "
            class="btn btn-primary"
            (click)="createModal(editConsig)"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="value === 'gestionViewConsig'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Detalle de la Consignación
        </p>
      </div>
    </div>

    <div class="mt-3">
      <p class="text-title text-black text-bold">Nombre de la Agencia</p>
    </div>

    <div class="mt-3" nz-row nzAlign="middle">
      <div nz-col>
        <div
          class="estadoConsig"
          [ngStyle]="{
            background: apiAdminCoguasimales.selectedConsig.estado.background
          }"
        >
          <p class="text-bold">
            {{ apiAdminCoguasimales.selectedConsig.estado.nombre }}
          </p>
        </div>
      </div>
    </div>

    <div
      *ngIf="apiAdminCoguasimales.selectedConsig.estado.nombre === 'RECHAZADA'"
      class="mt-4"
      nz-row
      nzAlign="middle"
    >
      <div nz-col>
        <div>
          <p class="text-bold text-title text-primary">Motivo de Rechazo</p>
        </div>
        <div class="content-details mt-3">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada del motivo por el cual rechazaron la
              consignación que se realizo
            </p>
            <small>Motivo de rechazo</small>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="apiAdminCoguasimales.selectedConsig.estado.nombre === 'DEVUELTA'"
      class="mt-4"
      nz-row
      nzAlign="middle"
    >
      <div nz-col>
        <div>
          <p class="text-bold text-title text-primary">Motivo de devolución</p>
        </div>
        <div class="mt-3">
          <div class="rejected-details">
            <i class="far fa-times-circle"></i>
            <div class="description-reject">
              <p class="text-bold text-black">Número de consignación</p>
              <small>El número de la consignación no coincide</small>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="rejected-details">
            <i class="far fa-times-circle"></i>
            <div class="description-reject">
              <p class="text-bold text-black">Valor de la consignación</p>
              <small>Problemas en el valor de la consignación</small>
            </div>
          </div>
        </div>

        <div class="content-details mt-2">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada del motivo de devolución de la consginación
              que se realizo
            </p>
            <small>Motivo de devolución</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 position-relative">
      <div class="content-imgVoucherActions">
        <img src="./assets/img/imgVoucherTest.png" alt="" />

        <div class="description">
          <p>Comprobante de consignación</p>
          <div class="imgVoucherActions">
            <i (click)="viewImgConsign()" class="fas fa-search-plus"></i>
            <i
              (click)="downloadConsig('https://picsum.photos/200/300')"
              class="fas fa-arrow-down"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fal fa-university"></i>
          <div class="description-details">
            <p class="text-bold text-black">Bancolombia</p>
            <small>Banco</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-hashtag"></i>
          <div class="description-details">
            <p class="text-bold text-black">00000000000</p>
            <small>Número de cuenta</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-calendar-alt"></i>
          <div class="description-details">
            <p class="text-bold text-black">DD/MM/YYYY</p>
            <small>Fecha de consignación</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-hashtag"></i>
          <div class="description-details">
            <p class="text-bold text-black">123456789</p>
            <small>Número de consignación</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <i class="fas fa-dollar-sign"></i>
          <div class="description-details">
            <p class="text-bold text-black">5.000.000.000</p>
            <small>Valor de la consignación</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle">
      <div nz-col>
        <div class="content-details">
          <div class="description-details">
            <p class="text-bold text-black">
              Descripción detallada de la consignación que se realizara
            </p>
            <small>Observaciones</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="middle" nzJustify="center" nzGutter="40">
      <div
        *ngIf="
          apiAdminCoguasimales.selectedConsig.estado.nombre === 'PENDIENTE'
        "
        nz-col
      >
        <div (click)="validateConsig()" class="icon-edit">
          <span class="tiptext">Validar consignación</span>
          <img src="./assets/img/validateConsig.svg" alt="" />
        </div>
      </div>
      <div nz-col>
        <div (click)="viewRecord()" class="icon-edit">
          <span class="tiptext">Historial de movimientos</span>
          <img src="./assets/img/record.svg" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="value === 'recordConsig'">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Histórico de la consignación
        </p>
      </div>
    </div>

    <div class="mt-4 timeline">
      <nz-timeline>
        <nz-timeline-item nzColor="#00AEC7">
          <p class="text-title" style="color: #00aec7">Pendiente</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
        </nz-timeline-item>
        <nz-timeline-item nzColor="#DDC91C">
          <p class="text-title" style="color: #ddc91c">Devuelta</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
          <p class="text-title">Analista: Nombre de la persona</p>
        </nz-timeline-item>

        <nz-timeline-item nzColor="#00AEC7">
          <p class="text-title" style="color: #00aec7">Pendiente</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
          <p class="text-title">Analista: Nombre de la persona</p>
        </nz-timeline-item>

        <nz-timeline-item nzColor="#AA3434">
          <p class="text-title" style="color: #aa3434">Rechazada</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
          <p class="text-title">Analista: Nombre de la persona</p>
        </nz-timeline-item>

        <nz-timeline-item nzColor="#183E8D">
          <p class="text-title" style="color: #183e8d">Aprobada</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
          <p class="text-title">Analista: Nombre de la persona</p>
        </nz-timeline-item>

        <nz-timeline-item nzColor="#707070">
          <p class="text-title" style="color: #707070">Anulada</p>
          <p class="text-title text-bold text-black">DD/MM/YYYY - HH:MM:SS</p>
          <p class="text-title">Agencia: Nombre de la persona</p>
          <p class="text-title">Analista: Nombre de la persona</p>
        </nz-timeline-item>
      </nz-timeline>
    </div>
  </div>

  <div *ngIf="value === 'validConsig'" style="position: relative">
    <div nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary text-large">
          Validar Consignación
        </p>
      </div>
    </div>

    <div class="mt-4" style="height: 150px"></div>

    <div class="mt-4 contentImgVoucher">
      <div class="imgVoucher">
        <img src="./assets/img/imgVoucherTest.png" alt="" />
      </div>
    </div>

    <div class="mt-4" nz-row nzAlign="top">
      <div nz-col>
        <p class="text-bold text-title text-primary">Información a validar</p>
      </div>
    </div>

    <div class="mt-3" nz-row nzAlign="top">
      <div nz-col>
        <p>Marca con un check los datos correctos</p>
      </div>
    </div>

    <div class="mt-3 listValidate">
      <div>
        <p class="text-bold text-black">Bancolombia</p>
        <small>Banco</small>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="test"></label>
      </div>
    </div>

    <div class="mt-3 listValidate">
      <div>
        <p class="text-bold text-black">000000000000000</p>
        <small>Número de cuenta</small>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="test1"></label>
      </div>
    </div>

    <div class="mt-3 listValidate">
      <div>
        <p class="text-bold text-black">DD/MM/AAAA</p>
        <small>Fecha de consignación</small>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="test2"></label>
      </div>
    </div>

    <div class="mt-3 listValidate">
      <div>
        <p class="text-bold text-black">1234567890</p>
        <small>Número de consignación</small>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="test3"></label>
      </div>
    </div>

    <div class="mt-3 listValidate">
      <div>
        <p class="text-bold text-black">5.000.000,00</p>
        <small>Valor de la consignación</small>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="test4"></label>
      </div>
    </div>

    <div class="mt-3">
      <label>Observación</label>
      <textarea
        nz-input
        [(ngModel)]="testText"
        maxlength="100"
        [nzAutosize]="{ minRows: 3, maxRows: 3 }"
        placeholder="Ingresar observación"
      ></textarea>
    </div>

    <div class="mt-5" nz-row nzAlign="middle" nzJustify="space-between">
      <div (click)="createModal(rejectConsig)" nz-col>
        <p class="close-drawer">Rechazar</p>
      </div>
      <div nz-col>
        <button class="btn btn-primary" (click)="createModal(validConsig)">
          Guardar
        </button>
      </div>
    </div>
  </div>

  <!--  -->

  <!-- ICONO -->

  <div class="close-drawer-icon">
    <i (click)="closeDrawer()" class="fas fa-times"></i>
  </div>
</div>

<!-- MODALES -->

<ng-template #createBank let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de creación de banco
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">¿Está seguro que desea registrar este banco?</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(createdBank, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createdBank let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">Banco registrado correctamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #editBank let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de edición de banco
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">¿Está seguro que desea editar este banco?</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(editedBank, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editedBank let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">Banco editado correctamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #createConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de creación de consignación
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        ¿Está seguro que desea registrar esta consignación?
      </p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(createdConsig, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de edición de consignación
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        ¿Está seguro que desea editar esta consignación?
      </p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(editedConsig, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editedConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">{{ msj }}</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #createdConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">{{ msj }}</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #rejectConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de rechazo de consignación
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        ¿Está seguro que desea rechazar esta consignación?
      </p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(rejectedConsig, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #rejectedConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">Consignación rechazada correctamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #validConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-title text-bold text-primary-light text-small">
        Confirmación de validación de consignación
      </p>
    </div>
  </div>

  <div class="mt-3" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center">
        ¿Está seguro que desea validar esta consignación?
      </p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzGutter="23" nzJustify="center">
    <div nz-col class="gutter-row">
      <button class="btn btn-outline-danger" (click)="destroyModal(ref)">
        Cancelar
      </button>
    </div>
    <div nz-col class="gutter-row">
      <button
        (click)="openModalRef(valideConsig, ref, closeIcon)"
        class="btn btn-secondary"
      >
        Confirmar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #valideConsig let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <p class="text-center text-bold">Consignación validada correctamente</p>
    </div>
  </div>

  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col>
      <button (click)="closeAll(ref)" class="btn btn-secondary">Aceptar</button>
    </div>
  </div>
</ng-template>

<ng-template #imgConsign let-ref="modalRef">
  <div class="mt-4" nz-row nzAlign="middle" nzJustify="center">
    <div nz-col nzSpan="24">
      <div class="imgConsig">
        <img
          [src]="
            apiAdminAgencia.detailsConsig?.voucherImage.voucherFileLink
              ? apiAdminAgencia.detailsConsig?.voucherImage.voucherFileLink
              : './assets/img/imgVoucherTest.png'
          "
          alt=""
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>
</ng-template>
