import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AgenciaService } from 'projects/coguasimales/src/app/agencia/services/agencia.service';

@Component({
  selector: 'app-modal-edit-agency',
  templateUrl: './modal-edit-agency.component.html',
  styleUrls: ['./modal-edit-agency.component.scss'],
})
export class ModalEditAgencyComponent implements OnInit {
  constructor(private modal: NzModalRef, public apiAgencia: AgenciaService) {}

  ngOnInit(): void {}

  destroyModal() {
    this.modal.destroy();
  }

  confirEditarAgency() {
    this.apiAgencia.isAgencyEdit = true;
    this.modal.destroy();
  }
}
