import { AbstractControl, ValidatorFn } from '@angular/forms';

export function fvalidatorFactura(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let x = parseFloat(control.value);
    if (
      (control.value && control.value.toString().trim() == '') ||
      control.value == null ||
      control.value == ''
    )
      return null;

    if (x < 5000 || isNaN(x)) {
      console.log(control.parent);
      return { validatorsFactura: true };
    }
    return null;
  };
}
