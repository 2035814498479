<div class="container">
  <div class="section-info">
    <p>
      Agencia bloqueada por {{ info.status.userStatus }} el
      {{ date }}
    </p>
  </div>
  <div class="section-button" (click)="unlock()">
    <p>Desbloquear Agencia</p>
  </div>
</div>
