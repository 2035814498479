import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'steppers',
  templateUrl: './steppers.component.html',
  styleUrls: ['./steppers.component.scss'],
})
export class SteppersComponent implements OnInit {
  @Input() step;
  @Input() steppers;
  deviceWidth: number;
  // arrayStep = [];

  constructor() {
    this.deviceWidth = window.innerWidth;
    // this.arrayStep = [
    //   {
    //     nombre: 'Validación de cuenta de usuario',
    //     touched: true,
    //     active: false,
    //   },
    //   {
    //     nombre: 'Representante Legal',
    //     touched: false,
    //     active: true,
    //   },
    //   {
    //     nombre: 'Validación Geográfica',
    //     touched: false,
    //     active: false,
    //   },
    //   {
    //     nombre: 'Validación de Fachada',
    //     touched: false,
    //     active: false,
    //   },
    // ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('steppers', changes);
    let indexPre = changes.step.previousValue
      ? changes.step.previousValue - 1
      : 0;
    let indexCurrent = changes.step.currentValue - 1;

    // if (indexPre) {
    this.steppers[indexPre].touched = true;
    this.steppers[indexPre].active = false;
    // }

    this.steppers[indexCurrent].touched = false;
    this.steppers[indexCurrent].active = true;
  }

  ngOnInit(): void {
    if (this.step >= 3) {
      for (let index = 0; index < this.step - 1; index++) {
        this.steppers[index].touched = true;
        this.steppers[index].active = false;
      }
    }
  }
}
