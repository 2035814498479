<nz-table
  #rowSelectionTable
  nzShowSizeChanger
  [nzData]="rows"
  [nzShowSizeChanger]="false"
  [nzSimple]="false"
  [nzPageSize]="5"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th></th>
      <th nzAlign="center" *ngFor="let header of tableHeaders">
        {{ header.label }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{ styleTable: i % 2 === 0 }"
      *ngFor="let dataRow of rowSelectionTable.data; let i = index"
    >
      <td
        [nzChecked]="setOfCheckedId.has(dataRow.id)"
        (nzCheckedChange)="onItemChecked(dataRow.id, $event, dataRow)"
      ></td>

      <td
        nzAlign="center"
        *ngFor="let item of dataRow | keyvalue: unsorted; let first = first"
        [ngClass]="{ first: first }"
      >
        {{ item.value }}
      </td>
    </tr>
  </tbody>
</nz-table>
