import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/agencia/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CajaService {
  _baseURL: string = environment.base;
  isCollapse: boolean;
  isFinishPay: boolean;

  $detectedItemPurchase = new Subject();
  detectedErrorScrolling$ = new Subject();
  optionMenuFunctional$ = new Subject();

  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getDetalisAgency(): Observable<any> {
    const url = `${this._baseURL}/register/agency/detail`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListTerms(): Observable<any> {
    const url = `${this._baseURL}/register/agency/terms-conditions`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  saveListTerms(body): Observable<any> {
    const url = `${this._baseURL}/register/agency/terms-conditions`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListToPremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/premium/apply`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  confirActivePremium(): Observable<any> {
    const url = `${this._baseURL}/dashboard/confirm-premium`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  transactionsMenu(): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/menu`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getBalance(): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/balance`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getListConvenios(): Observable<any> {
    const url = `${this._baseURL}/dashboard/transactions/menu/recaudo-directo/convenios`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
