import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { GeneralModalComponent } from '../general-modal/general-modal.component';
import { DatePipe } from '@angular/common';
import { RegisterServiceService } from 'projects/agencia/src/app/home/register/service/register-service.service';

@Component({
  selector: 'app-agencia-premium-modal',
  templateUrl: './agencia-premium-modal.component.html',
  styleUrls: ['./agencia-premium-modal.component.scss'],
})
export class AgenciaPremiumModalComponent implements OnInit {
  fileExist: any;
  today = new Date();
  checked = true;
  formAgencia: FormGroup;
  dateString: string;
  validDate: boolean;

  constructor(
    private modal: NzModalRef,
    private modalService: NzModalService,
    public fb: FormBuilder,
    public datePipe: DatePipe,
    public apiRegister: RegisterServiceService
  ) {
    this.formAgencia = this.fb.group({
      nameComercio: ['', Validators.required],
      date: ['', Validators.required],
      nit: ['', Validators.required],
    });
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  get f() {
    return this.formAgencia.controls;
  }

  ngOnInit(): void {
    this.loadData();
  }

  detectedDate(e) {
    this.validDate = e;
    this.f.date.markAllAsTouched();
  }

  loadData() {
    // this.apiRegister.getDocSolic().subscribe(
    //   (resp)=>{
    //     console.log(resp);
    //   },
    //   (error)=>{
    //     console.log(error);
    //   }
    // )
  }

  getFile(e) {
    console.log(e);

    this.fileExist = e;
  }

  getDate(e) {
    this.dateString = this.datePipe.transform(e, 'yyyy-MM-dd');
  }

  aceptAgenciaPremium() {
    // let body = {}

    // this.apiRegister.saveDocSolicPremium(body).subscribe(
    //   (resp)=>{
    //     console.log(resp);
    //   },
    //   (error)=>{
    //     console.log(error);
    //   }
    // )

    this.destroyModal();

    //CONFIG SOLICITUD RECIBIDA
    let solicitRecibida = {
      title: 'Solicitud recibida',
      text1:
        'Su solicitud para ser una de nuestras agencias fue recibida y pasará por el proceso de revisión correspondiente.',
      text2:
        'Recibirá notificaciones en su correo electrónico, o a través de nuestra plataforma cuando inicie sesión.',
      button: 'Entendido',
    };
    this.modalService.create({
      nzMaskClosable: false,
      nzCancelText: null,
      nzOkText: null,
      nzClosable: false,
      nzComponentParams: { solicitRecibida },
      nzStyle: {},
      nzWidth: 500,
      nzFooter: null,
      nzBodyStyle: {
        padding: '20px',
        color: '#707070',
      },
      nzContent: GeneralModalComponent,
    });
  }

  destroyModal(): void {
    this.modal.destroy();
  }
}
