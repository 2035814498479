<div class="container">
  <div style="position: relative">
    <google-map
      class="position-relative"
      height="150px"
      width="100%"
      [center]="center"
      [zoom]="zoom"
      [options]="options"
      disableDefaultUI="false"
    >
      <map-marker
        *ngFor="let marker of markers"
        [position]="marker.position"
        [label]="marker.label"
        [title]="marker.title"
        [options]="marker.options"
      >
      </map-marker>
    </google-map>
    <div
      class="status"
      [ngStyle]="{ 'background-color': detalleAgencia?.status.color }"
    >
      <p class="text-title text-bold text-medium">
        {{ detalleAgencia?.status.description }}
      </p>
    </div>
    <div class="address">
      <small class="text-bold">{{ detalleAgencia?.address.address }}</small>
    </div>
  </div>

  <div class="container-info">
    <div nz-row nzAlign="middle">
      <div nz-col>
        <p class="text-medium text-primary text-title text-bold">
          {{ detalleAgencia?.agencyName }}
        </p>
        <p class="text-medium text-primary text-title text-bold">
          NIT {{ detalleAgencia?.nit }}
        </p>
      </div>
    </div>

    <div class="mt-1" nz-row nzAlign="middle">
      <div nz-col>
        <p class="text-xsmall">
          Constituida el {{ detalleAgencia?.constDate | date: "dd/MM/yyyy" }}
        </p>
      </div>
    </div>

    <div class="mt-1" nz-row nzAlign="middle">
      <div nz-col>
        <div class="info text-xsmall">
          <i class="fas fa-map-marker-alt"></i>
          <p class="">{{ detalleAgencia?.address.address }}</p>
        </div>
        <div class="info text-xsmall">
          <i class="fas fa-phone-alt"></i>
          <p class="">
            {{ detalleAgencia?.phone1 }}
            <span *ngIf="detalleAgencia?.phone2"
              >/ {{ detalleAgencia?.phone2 }}</span
            >
          </p>
        </div>
        <div class="info text-xsmall">
          <i class="fas fa-envelope"></i>
          <p class="">{{ detalleAgencia?.email }}</p>
        </div>
      </div>
    </div>

    <div class="container-menu mt-4">
      <div
        class="menu-item"
        [ngClass]="{ active: item.active }"
        *ngFor="let item of menu; let i = index"
      >
        <div (click)="selected(i)" class="item">
          <i [class]="item.icono"></i>
          <p>{{ item.name }}</p>
        </div>
        <div *ngIf="item.notificacion" class="notificacion">
          {{ item.notificacion }}
        </div>
      </div>
    </div>
  </div>
</div>
