import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/coguasimales/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginCoguasimalesService {
  _baseURL: string = environment.base;

  errorSesion: boolean;
  msjErrorSesion: string;

  constructor(private http: HttpClient) {}

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  loginCoguasimales(body): Observable<any> {
    const url = `${this._baseURL}/login/coguasimales`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  logoutCoguasimales(): Observable<any> {
    const url = `${this._baseURL}/coguasimales/logout`;

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            Accept: '*/*',
            Authorization: this.getToken(),
          }),
        }
      )
      .pipe(map((data) => data));
  }

  logoutAllSesion(body): Observable<any> {
    const url = `${this._baseURL}/login/close-sessions`;

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }
}
