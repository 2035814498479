<div nz-row nzAlign="middle" nzJustify="center">
  <div class="text-center" nz-col>
    <p class="text-bold text-title text-medium text-primary">
      Aplica como Agencia Premium y maximiza tus beneficios!
    </p>
  </div>
</div>

<div class="mt-1" nz-row nzAlign="middle" nzJustify="center">
  <div class="text-center" nz-col nzSpan="14">
    <p>
      Ya puedes disfrutar de nuestros servicios, solo resta que configures el
      tipo de agencia que quieres ser y obtener las mayores ganancias posibles
    </p>
  </div>
</div>

<nz-card class="mt-1">
  <div class="corner-icon premium">
    <i class="fas fa-trophy"></i>
  </div>
  <div nz-row nzGutter="16">
    <div nz-col nzSpan="12">
      <p class="text-bold text-medium color-premium text-title">
        Agencia Premium
      </p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
      <div class="container-check mt-2">
        <label nz-checkbox class="mr-2" [(ngModel)]="checked"></label>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit
        </p>
      </div>
      <div class="container-check mt-2">
        <label nz-checkbox class="mr-2" [(ngModel)]="checked"></label>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit
        </p>
      </div>
      <div class="container-check mt-2">
        <label nz-checkbox class="mr-2" [(ngModel)]="checked"></label>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit
        </p>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <form [formGroup]="formAgencia">
        <p class="text-bold text-medium text-title">Datos de Comercio</p>
        <div>
          <label>Nombre del Comercio <span class="text-danger">*</span></label>
          <input
            nz-input
            formControlName="nameComercio"
            nzSize="large"
            placeholder=""
          />
          <div
            class="absolute"
            *ngIf="f.nameComercio.invalid && f.nameComercio.touched"
          >
            <small *ngIf="f.nameComercio.errors?.required" class="text-danger"
              >Este campo es obligatorio</small
            >
          </div>
        </div>
        <div class="mt-3">
          <label
            >NIT - Número de Registro <span class="text-danger">*</span></label
          >
          <input nz-input formControlName="nit" nzSize="large" placeholder="" />
          <div class="absolute" *ngIf="f.nit.invalid && f.nit.touched">
            <small *ngIf="f.nit.errors?.required" class="text-danger"
              >Este campo es obligatorio</small
            >
            <small *ngIf="f.nit.errors?.pattern" class="text-danger"
              >Número no válido</small
            >
          </div>
        </div>

        <div class="mt-3">
          <label
            >Fecha de constitución <span class="text-danger">*</span></label
          >
          <nz-date-picker
            [nzDisabledDate]="disabledDate"
            [nzInputReadOnly]="true"
            formControlName="date"
            [nzFormat]="'dd/MM/yyyy'"
            class="w-100"
            nzSize="large"
            (ngModelChange)="getDate($event)"
            (nzOnOpenChange)="detectedDate($event)"
          ></nz-date-picker>
          <div class="absolute" *ngIf="!validDate && f.date.touched">
            <small *ngIf="f.date.errors?.required" class="text-danger"
              >Este campo es obligatorio</small
            >
          </div>
        </div>

        <div class="mt-3">
          <label>Cámara de Comercio <span class="text-danger">*</span> </label>
          <file-uploader
            (fileEmitter)="getFile($event)"
            [fileExist]="fileExist"
            [title]="'Adjunte la cámara de comercio'"
          ></file-uploader>
        </div>
      </form>
    </div>
  </div>

  <div class="mt-1" nz-row nzJustify="space-between">
    <div nz-col>
      <p class="link">Omitir</p>
    </div>
    <div nz-col>
      <button
        [disabled]="!formAgencia.valid || !dateString || !fileExist"
        (click)="aceptAgenciaPremium()"
        class="btn btn-contrast-color2"
      >
        Continuar
      </button>
    </div>
  </div>
</nz-card>
