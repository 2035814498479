<div class="container">
  <div nz-row nzAlign="middle">
    <div nz-col>
      <p class="text-title text-bold text-primary">Búsqueda de Cliente</p>
    </div>
  </div>

  <form [formGroup]="formSearchClient">
    <div class="mt-1" nz-row nzAlign="middle" nzGutter="8">
      <div nz-col nzSpan="6" class="gutter-row">
        <nz-select
          nzSize="large"
          nzPlaceHolder=""
          formControlName="identificationType"
        >
          <nz-option
            *ngFor="let item of listType"
            [nzLabel]="item.code"
            [nzValue]="item.id"
          ></nz-option>
        </nz-select>
      </div>
      <div nz-col nzSpan="18">
        <input
          class="fieldSearch"
          type="text"
          nzSize="large"
          formControlName="search"
          (keyup.enter)="searchClient()"
          nz-input
          placeholder="Num. de identificación"
        />
        <div (click)="searchClient()" class="iconSearch">
          <i class="fas fa-search"></i>
        </div>
      </div>
    </div>
  </form>
</div>
