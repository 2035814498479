import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'section-balance',
  templateUrl: './section-balance.component.html',
  styleUrls: ['./section-balance.component.scss'],
})
export class SectionBalanceComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit(): void {}
}
