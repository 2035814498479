<div class="container-modal">
  <div class="close-modal">
    <i class="fas fa-times-circle"></i>
  </div>

  <div nz-row nzAlign="middle" nzJustify="center">
    <div nz-col nzSpan="14">
      <p class="text-bold text-medium text-primary-light text-center">
        Hemos finalizado su registro con éxito!
      </p>
    </div>
    <button (click)="test()">asdsad</button>
  </div>
</div>
