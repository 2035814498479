import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AgenciaService } from 'projects/coguasimales/src/app/agencia/services/agencia.service';

@Component({
  selector: 'action-agency-cancel',
  templateUrl: './action-agency-cancel.component.html',
  styleUrls: ['./action-agency-cancel.component.scss'],
})
export class ActionAgencyCancelComponent implements OnInit {
  @Input() idAgencia;
  @Output() eventChangeAgency = new EventEmitter<any>();
  @ViewChild('uploader') inputFile: ElementRef;

  listFile = [];
  listOptionDecline = [];
  msjErrorFile: string =
    'Solo puedes adjuntar 5 archivos en formato PDF con tamaño máximo de 3MB';
  msjSuccess: string;
  errorFile: boolean;
  formAgencyCancel: FormGroup;

  constructor(
    public fb: FormBuilder,
    private modalService: NzModalService,
    public apiAgencia: AgenciaService
  ) {
    this.formAgencyCancel = this.fb.group({
      reason: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    console.log(this.idAgencia);

    this.loadData();
  }

  get f() {
    return this.formAgencyCancel.controls;
  }

  loadData() {
    this.apiAgencia.listReasonCancelAgency().subscribe(
      (resp) => {
        console.log(resp);
        this.listOptionDecline = resp;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fileChangeEvent(e) {
    let formValid = 'pdf';
    let format = e[0].name.split('.').pop();

    let sizeInMB = (e[0].size / (1024 * 1024)).toFixed(2);
    let maxSize = 3;

    if (!formValid.includes(format)) {
      this.msjErrorFile = `Solo permite formato PDF`;
      this.errorFile = true;
      return;
    }

    if (parseFloat(sizeInMB) > maxSize) {
      this.msjErrorFile = `Peso máximo permitido 3MB`;
      this.errorFile = true;
      return;
    }

    if (this.listFile.length === 5) {
      this.msjErrorFile = 'Cantidad máxima permitida 5 archivos';
      this.errorFile = true;
      return;
    }

    this.msjErrorFile = '';
    this.errorFile = false;

    this.listFile.push({
      file: e[0],
      size: sizeInMB,
    });
    this.inputFile.nativeElement.value = '';
  }

  deleteFile(data, index) {
    this.listFile.splice(index, 1);
  }

  open(uploader) {
    uploader.click();
  }

  destroyModal(id) {
    id.destroy();
  }

  confirCancelAgency(modal, closeIcon) {
    this.modalService.create({
      nzCancelText: null,
      nzOkText: null,
      nzFooter: null,
      nzStyle: {
        top: '25%',
      },
      nzCloseIcon: closeIcon,
      nzContent: modal,
    });
  }

  cancelAgency(modal, id, closeIcon) {
    let closeFiles = [];

    this.listFile.forEach((element) => {
      closeFiles.push(element);
    });

    let body = {
      closeOptionId: this.f.reason.value,
      closeFile: closeFiles,
    };
    this.apiAgencia.cancelAgency(this.idAgencia, body).subscribe(
      (resp) => {
        console.log(resp);
        this.msjSuccess = resp.message;
        this.destroyModal(id);
        this.modalService.create({
          nzCancelText: null,
          nzOkText: null,
          nzFooter: null,
          nzStyle: {
            top: '25%',
          },
          nzCloseIcon: closeIcon,
          nzContent: modal,
        });
        this.apiAgencia.detailsAgency(this.idAgencia).subscribe(
          (resp) => {
            this.apiAgencia.detalleAgencia = resp.leftPanel;
            this.apiAgencia.infoGeneral = resp.generalInformation;
            this.apiAgencia.agencyActive = false;
            this.eventChangeAgency.emit(false);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        console.log(error);
        this.destroyModal(id);
      }
    );
  }
}
