import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getFormatedDateAndHours } from 'src/functions/formatedDateAndHours';

@Component({
  selector: 'notification-block-agency',
  templateUrl: './notification-block-agency.component.html',
  styleUrls: ['./notification-block-agency.component.scss'],
})
export class NotificationBlockAgencyComponent implements OnInit {
  @Input() info;
  @Output() eventUnlockAgency = new EventEmitter<any>();

  date: any;
  constructor() {}

  ngOnInit(): void {
    this.date = getFormatedDateAndHours(this.info.status.dateStatus);
  }

  unlock() {
    this.eventUnlockAgency.emit('Desbloquear');
  }
}
