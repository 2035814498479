import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SiderbarMenuComponent } from './siderbar-menu/siderbar-menu.component';
import { TabsComponent } from './tabs/tabs.component';
import { TableMultiselectComponent } from './tables/table-multiselect/table-multiselect.component';
import { TableSingleSelectComponent } from './tables/table-single-select/table-single-select.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarTransComponent } from './navbar-trans/navbar-trans.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ModalSuccessComponent } from './Modals/modal-success/modal-success.component';
import { SteppersComponent } from './steppers/steppers.component';
import { FileDragComponent } from './file-drag/file-drag.component';
import { GeneralModalComponent } from './Modals/general-modal/general-modal.component';
import { AceptTermsModalComponent } from './Modals/acept-terms-modal/acept-terms-modal.component';
import { AgenciaPremiumModalComponent } from './Modals/agencia-premium-modal/agencia-premium-modal.component';
import { NextInputDirective } from './directives/next-input.directive';
import { InfoDetalleAgenciaComponent } from './info-detalle-agencia/info-detalle-agencia.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ModalErrorComponent } from './Modals/modal-error/modal-error.component';
import { SearchClientComponent } from './search-client/search-client.component';
import { PurchaseSummaryComponent } from './purchase-summary/purchase-summary.component';
import { InfoPurchaseComponent } from './info-purchase/info-purchase.component';
import { DetailsPackageComponent } from './details-service/details-service.component';
import { AcountProfileComponent } from './Modals/acount-profile/acount-profile.component';
import { ActionAgencyCancelComponent } from './action-agency-cancel/action-agency-cancel.component';
import { ActionsAgenciaComponent } from './actions-agencia/actions-agencia.component';
import { NotificationBlockAgencyComponent } from './notification-block-agency/notification-block-agency.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TableCustomComponent } from './tables/table-custom/table-custom.component';
import { ModalBlockAgencyComponent } from './Modals/modal-block-agency/modal-block-agency.component';
import { TableEditableComponent } from './tables/table-editable/table-editable.component';
import { NgxMaskModule } from 'ngx-mask';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalMessageComponent } from './Modals/modal-message/modal-message.component';
import { SectionBalanceComponent } from './section-balance/section-balance.component';
import { ModalEditAgencyComponent } from './Modals/modal-edit-agency/modal-edit-agency.component';
import { FormEditAgencyComponent } from './form-edit-agency/form-edit-agency.component';
import { DrawerComponent } from './drawer/drawer.component';
import { SteppersPremiumComponent } from './steppers-premium/steppers-premium.component';

@NgModule({
  declarations: [
    NavBarComponent,
    SiderbarMenuComponent,
    TabsComponent,
    TableMultiselectComponent,
    TableSingleSelectComponent,
    FooterComponent,
    NavbarTransComponent,
    FileUploaderComponent,
    ModalSuccessComponent,
    SteppersComponent,
    FileDragComponent,
    GeneralModalComponent,
    AceptTermsModalComponent,
    AgenciaPremiumModalComponent,
    NextInputDirective,
    InfoDetalleAgenciaComponent,
    ModalErrorComponent,
    SearchClientComponent,
    PurchaseSummaryComponent,
    InfoPurchaseComponent,
    DetailsPackageComponent,
    AcountProfileComponent,
    ActionAgencyCancelComponent,
    ActionsAgenciaComponent,
    NotificationBlockAgencyComponent,
    BreadcrumbComponent,
    TableCustomComponent,
    ModalBlockAgencyComponent,
    TableEditableComponent,
    SpinnerComponent,
    ModalMessageComponent,
    SectionBalanceComponent,
    FormEditAgencyComponent,
    ModalEditAgencyComponent,
    DrawerComponent,
    SteppersPremiumComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    GoogleMapsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    SiderbarMenuComponent,
    TabsComponent,
    TableMultiselectComponent,
    TableSingleSelectComponent,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    NavbarTransComponent,
    FileUploaderComponent,
    SteppersComponent,
    FileDragComponent,
    NextInputDirective,
    InfoDetalleAgenciaComponent,
    SearchClientComponent,
    PurchaseSummaryComponent,
    InfoPurchaseComponent,
    DetailsPackageComponent,
    AcountProfileComponent,
    ActionAgencyCancelComponent,
    ActionsAgenciaComponent,
    NotificationBlockAgencyComponent,
    BreadcrumbComponent,
    TableCustomComponent,
    TableEditableComponent,
    SpinnerComponent,
    SectionBalanceComponent,
    FormEditAgencyComponent,
    ModalEditAgencyComponent,
    DrawerComponent,
    SteppersPremiumComponent,
  ],
})
export class SharedModule {}
