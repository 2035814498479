import { CajaService } from 'projects/agencia/src/app/caja/services/caja.service';
import { AgenciaService } from './../../../../projects/coguasimales/src/app/agencia/services/agencia.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() listLink;

  constructor(
    public route: Router,
    public apiAgencia: AgenciaService,
    public apiCaja: CajaService
  ) {}

  ngOnInit(): void {}

  toLink(data) {
    if (data?.router) {
      this.route.navigate([data?.router]);
      this.apiAgencia.isAgencyBlock = false;
      this.apiAgencia.isAgencyEdit = false;
    } else {
      this.apiCaja.isFinishPay = data.isCancelPay;
    }
  }
}
